function formatAmount(number) {
    if (number == null || isNaN(number)) {
        return '0'; // Return a default value for undefined, null, or non-numeric input
    }
    if (number >= 10000000) {
        return (number / 10000000).toFixed(2) + ' C';
    } else if (number >= 100000) {
        return (number / 100000).toFixed(2) + ' lakh';
    } else {
        return number.toString();
    }
}

export default formatAmount;
