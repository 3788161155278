import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import VoucherPopup from './VoucherPopup1';
import formatAmount from '../utilities/formatAmount';
import formatDate from '../utilities/formatDate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme, Box, Button, ClickAwayListener, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';



const Table = ({ data }) => {
    const [showVoucher, setShowVoucher] = useState(false);
    const [attachmentData, setAttachmentData] = useState([]);

    const [voucherData, setVoucherData] = useState({
        voucherId: null,
        expenseType: "",
        subType: "",
        expenseDate: "2024-05-23T00:00:00",
        expenseAmount: null,
        attachment: null,
        attachmentPath: "",
        description: "",
        applyDate: "2024-05-03T00:00:00",
        fromPlace: "",
        toPlace: "",
        distance: 0,
        fromDate: "1900-01-01T00:00:00",
        toDate: "1900-01-01T00:00:00",
        userId: 0,
        userName: "",
        role: "",
        status: "",
        entryBy: "",
        donationType: "",
        donationFor: "",
        adminActionDate: "1900-01-01T00:00:00",
        adminRemark: "",
        accountantActionDate: "1900-01-01T00:00:00",
        accountantRemark: ""
    });

    const dispatch = useDispatch();
    const [visiblePopup, setVisiblePopup] = useState(null);
    const userData = useSelector((state) => state.auth.user);
    

    const navigate = useNavigate();
    const location = useLocation();
    const eventId = location.state?.eventId;
  
    console.log('Event ID:', eventId);

    const handleTogglePopup = (eventId) => {
        setVisiblePopup(prevState => (prevState === eventId ? null : eventId));
    };

    const handleGetVoucherData = async (voucherId) => {
        const voucherDetailsUrl = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;
        const attachmentDetailsUrl = `https://expenseapi.sarahtech.com/api/Accounts/get_Attachments_details_by_voucher_id/${voucherId}`;
      
        try {
          // Fetch voucher details first
          const voucherDetailsResponse = await axios.get(voucherDetailsUrl);
      
          // Set voucher details and show voucher UI
          setVoucherData(voucherDetailsResponse.data);
          setShowVoucher(true);
          console.log('Voucher Details:', voucherDetailsResponse.data);
      
          try {
            // Attempt to fetch attachments
            const attachmentDetailsResponse = await axios.get(attachmentDetailsUrl);
            setAttachmentData(attachmentDetailsResponse?.data);
            console.log('Attachment Details:', attachmentDetailsResponse.data);
          } catch (attachmentError) {
            // Handle attachment fetch error
            console.warn('No attachments found or error fetching attachments:', attachmentError?.response?.data || attachmentError.message);
            setAttachmentData(null); // Clear attachment data if needed
          }
        } catch (voucherError) {
          // Handle errors for voucher details
          console.error('Error fetching voucher details:', voucherError?.response?.data || voucherError.message);
          toast.error("Failed to Load Expense Voucher.");
        }
      };
       
      

    const TableData = Array.isArray(data) ? data.map((item, index) => ({
        ...item,
        sn: index + 1,
    })) : [];
    // const userId = TableData[0].userId;
    // console.log('userid1', userId)

    const columns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{value}</div>
            }
        },
        {
            name: "expenseSubType",
            label: "Expense Type",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expenseAmount",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatAmount(value)} &nbsp;₹</div>
            }
        },
        {
            name: "expenseDate",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "entryDate",
            label: "Apply Date",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },

        {
            name: "userName",
            label: "Expense By",
            options: {
                display: false,

                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${value === "Accepted" && "text-yellow-500"} ${value === "Approved" && "text-green-500"} ${value === "Pending" && "text-orange-500"} ${value === "Rejected" && "text-red-600"} `} >{value}</p>
                ),
                filter: true,
                sort: true,
            }
        },

        {
            name: "userId",
            label: "User Id",
            options: {
                display: false,

                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "voucherId",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const status = tableMeta.rowData[6];  // Adjust index based on actual row data structure
                    const expenseTypeId = tableMeta.rowData[5];
                    const userId = tableMeta.rowData[7];

                    //  const userId = userId

                    const userRole = userData.role;
    console.log('table' , tableMeta)
                    return (
                        <div className="relative flex justify-start items-start">
                            <button
                                style={{ fontSize: 'inherit', fontFamily: 'inherit' }}
                                className='capitalize px-3 py-1 inline-block text-start cursor-pointer text-blue-500'
                                onClick={() => handleTogglePopup(value)}
                            >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </button>
                            {visiblePopup === value && (
                                <ClickAwayListener onClickAway={() => setVisiblePopup(null)}>
                                    <Paper
                                        className="absolute z-10 p-2 bg-white border rounded shadow"
                                        style={{
                                            top: 'calc(100% + 20px)', 
                                            right: 0, 
                                            transform: 'translateY(-100%)', 
                                            maxHeight: '200px', 
                                            overflowY: 'auto', 
                                            zIndex: 9999, 
                                        }}
                                    >
                                       
                                            <>
                                       
                                            {((userRole === "Admin" && status === "Accepted") || 
  (userRole === "Admin" && (status === "Pending" || status === "Rejected")) ||   (userRole === "User" && (status === "Pending" || status === "Rejected")) || 
  (userRole === "Accountant" && (status === "Pending" || status === "Rejected"))) && (
    <Button
        fullWidth
        variant="text"
        onClick={() => {
            if (userRole === "Admin") {
                navigate('/AdminUpdateVoucherDetails', { state: { eventId: eventId, voucherId: value } });
            } else if (userId === 33 && userRole === "Accountant") {
                navigate('/Updateaccforadmin', { state: { eventId: eventId, voucherId: value } });
            } else {
                navigate('/EditVoucher', { state: { eventId: eventId, voucherId: value } });
            }
        }}
    >
        Edit
    </Button>
)}

                                            
                                                <Button
                                                    fullWidth
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => { handleGetVoucherData(value); setShowVoucher(!showVoucher) }}
                                                >
                                                    Details
                                                </Button>
                                            </>
                                  
                                    </Paper>
                                </ClickAwayListener>
                            )}
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        selectableRows: 'none',
        filterType: 'dropdown',
        elevation: 0,
        rowsPerPageOptions: [10, 15, 25, 50],
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto'
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "start", // start align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });

    return (
        <>        
            {showVoucher && <VoucherPopup data={voucherData} attachmentData={attachmentData} openPopup={true} />}     
            <div className='py-5 min-h-screen flex justify-center items-center'>
                <Box width="100%">
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Expense Voucher List"}
                            data={TableData}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </Box>
            </div>
        </>
    )
}

export default Table;
