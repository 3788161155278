import React, { useState, useEffect } from 'react';
import { TERipple, TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ShowMoreText from '../utilities/ShowMoreText';
import formatDate from '../utilities/formatDate';
import getFileNameAndType from '../utilities/getFileNameAndType';
import pdfIcon from '../Images/PDF_file_icon.svg.png';
import imgIcon from '../Images/7156838.png';
import { toast } from "react-toastify";
import { getCurrentDate } from "../utilities/currentDateTime";
import formatExpenseDateInData from "../utilities/formatExpenseDateInData";
import { incrementRefresh, resetRefresh } from '../redux-toolkit/slice/refreshSlice';

const VoucherPopupbatchApproved = ({ open, onClose, selectedVouchers, data, totalAmount,openPopup  }) => {
    console.log('Received selectedVouchers in VoucherPopupbatchApproved:', selectedVouchers);


    const [showVoucher, setShowVoucher] = useState(openPopup);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [company, setCompany] = useState([]);
    const [bank, setBank] = useState([]);
    const [location, setLocation] = useState([]);
    const [remark, setRemark] = useState('');
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [error, setError] = useState('');
    const [batchId, setBatchId] = useState([]);
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        PaymentType: '',
        CompanyId: '',
        LocationId: '',
        BankId: '',
        paymentDate: ''
    });


    useEffect(() => {
        setShowVoucher(openPopup);
    }, [openPopup]);

    const handleClosePopup = () => {
        setShowVoucher(false); // Close the popup
    };

    const fetchBatchId = async () => {
        try {
            const response = await axios.get('https://expenseapi.sarahtech.com/api/Accounts/create_new_batch_id'); 
            setBatchId(response.data.batchId);
        } catch (error) {
            console.error('Error fetching batch ID:', error);
        }
    };

    const fetchUserVoucherData = async () => {
     
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_payment_details_for_accountant_by_voucher_id/${voucherId}`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            setUserVoucherData(data);
  
            console.log('User Voucher Data payment', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    };

    const fetchCompany = async () => {
        try {
          const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
          setCompany(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
 
    
    
    
      const handleApproveVoucher = async (voucher) => {
        try {
            const { voucherId, amount, userId, eventId } = voucher;
    
            // Prepare form data for multipart request
            const multipartData = new FormData();
            multipartData.append('VoucherId', voucherId);
            multipartData.append('batchId', batchId);
            multipartData.append('eventId', eventId);
            multipartData.append('Remark', remark);
            multipartData.append('amount', amount);
            multipartData.append('userId', userId);
            multipartData.append('companyId', formData.CompanyId);
            multipartData.append('locationId', formData.LocationId);
            multipartData.append('bankId', formData.BankId);
            multipartData.append('paymentType', formData.PaymentType);
            multipartData.append('paymentDate', formData.paymentDate);
            multipartData.append('AccountantId', userData.userId);
    
            // Attach the file if available
            if (formData.PaymentAttachment) {
                multipartData.append('PaymentAttachment', formData.PaymentAttachment);
            }
    
            console.log('Sending multipart data:', multipartData);
    
            // Send multipart data
            return await axios.put('https://expenseapi.sarahtech.com/api/Accounts/approved_by_accountant_batch', multipartData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (error) {
            console.error('Error approving voucher:', error);
            throw error; // Propagate error
        }
    };
    
    const handleApproveAllVouchers = async () => {
        if (remark.trim() === '') {
            toast.error('Remark is required to approve vouchers.');
            return;
        }
    
        try {
            console.log('Approving all vouchers:', selectedVouchers);
            await Promise.all(selectedVouchers.map((voucher) => handleApproveVoucher(voucher)));
            toast.success('All vouchers approved successfully!');
            setRemark('');
            dispatch(incrementRefresh());
            handleClosePopup();
        } catch (error) {
            console.error('Error approving all vouchers:', error);
            toast.error('Failed to approve all vouchers.');
        }
    };
    
    
    


    const handleChange = (e) => {
        console.log('handle change', e.target.value, e.target.name);
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    
     

    
          if (name === 'CompanyId') {
    
         
    
              const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;
          
              axios.get(url)
                .then((response) => {
                  console.log("bank: ", response.data);
                  setBank(response.data);
                  // Uncomment the line below if you want to show a success message to the user
                  // toast.success("Expense subtypes loaded successfully.");
                })
                .catch((err) => {
                  console.error('Response Error: ', err.response ? err.response.data : err.message);
                  toast.error("Failed to load expense subtypes.");
                });
          }

          if (name === 'CompanyId') {
    
         
    
            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_company_location_details_by_company_id/${value}`;
        
            axios.get(url)
              .then((response) => {
                console.log("location: ", response.data);
                setLocation(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load location.");
              });
        }
  
    
    
          if (name === 'BankId') {
            debugger
    
         
    
            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;
        
            axios.get(url)
              .then((response) => {
                console.log("Pay Type: ", response.data);
                setPaymentTypes(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load expense subtypes.");
              });
        }
      };


      useEffect(() => {
         fetchCompany();
         fetchUserVoucherData();
        fetchBatchId();

      }, []);

    const userData = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const { voucherId, expenseType, expenseSubType, expenseDate, expenseAmount, userName, attachmentPath, fromSource, toSource, distanceTravelled, fromDate, toDate, status, userId, eventId, expenseName } = data || {};
    const { fileName, fileType } = getFileNameAndType(attachmentPath);
    const fileUrl = `https://expenseapi.sarahtech.com/api/Files/view/${fileName}.${fileType}`;
console.log('object1212', expenseDate)
    return (
        
        <TEModal show={showVoucher} setShow={setShowVoucher}>
            <TEModalDialog size="lg">
                <TEModalContent>
                    <TEModalHeader>
                        <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                            Voucher Details
                        </h5>
                        <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={handleClosePopup}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </TEModalHeader>

                    <TEModalBody>
                        <div className="border border-black p-3">
                            <div className="flex px-8">
                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                    <p className="font-bold">Total Amount: <span className='font-normal'>{totalAmount}</span></p>
                                </div>
                                {/* <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
  <p className="font-bold">Selected UserName(s):</p>
  <div className="font-normal">
    {selectedVouchers.map((voucher, index) => (
      <span key={voucher.voucherId}>
        {voucher.userName}
        {index < selectedVouchers.length - 1 ? ', ' : ''}
      </span>
    ))}
  </div>
</div> */}

                            </div>

                            



                                
                              
        <>
          <div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-1/3">
        <label
            htmlFor="Company"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
            Company
        </label>
    </div>
    <div className="md:w-1/3">
        <select
            id="Company"
            name="CompanyId"
            value={formData.CompanyId || userVoucherData.companyId}  
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
        >
            {/* Show companyName but save companyId */}
            <option value={userVoucherData.companyId}>
                {userVoucherData.companyName}
            </option>
            {company.map((item) => (
                <option key={item.companyId} value={item.companyId}>
                    {item.companyName}
                </option>
            ))}
        </select>
    </div>
</div>


            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                    <label
                        htmlFor="Location"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                        Location
                    </label>
                </div>
                <div className="md:w-1/3">
                    <select
                        id="Location"
                        name="LocationId"
                        value={formData.LocationId || userVoucherData.LocationId}  
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                         <option value={userVoucherData.LocationId}>
                {userVoucherData.locationName}
            </option>
                        {location.map((item) => (
                            <option key={item.locationId} value={item.locationId}>
                                {item.companyLocation}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                    <label
                        htmlFor="Bank"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                        Bank
                    </label>
                </div>
                <div className="md:w-1/3">
                    <select
                        id="Bank"
                        name="BankId"
                        value={formData.BankId || userVoucherData.BankId}  
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                         <option value={userVoucherData.bankId}>
                {userVoucherData.bankName}
            </option>
                        {bank.map((item) => (
                            <option key={item.bankId} value={item.bankId}>
                                {item.bankName}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                    <label
                        htmlFor="PaymentTypes"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                        Payment Type
                    </label>
                </div>
                <div className="md:w-1/3">
                    <select
                        id="PaymentTypes"
                        name="PaymentType"
                        value={formData.PaymentType || userVoucherData.PaymentType}  

                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                         <option value={userVoucherData.paymentType}>
                {userVoucherData.paymentType}
            </option>
                        {paymentTypes.map((item) => (
                            <option key={item.paymentMasterId} value={item.paymentType}>
                                {item.paymentType}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="md:flex md:items-center mb-3 2xl:mb-6">
  <div className="md:w-1/3">
    <label
      htmlFor="Payment Date"
      className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      Payment Date:
    </label>
  </div>

  <div className="md:w-1/3">
    <input
      type="date"
      placeholder="Payment Date"
      name="paymentDate"
      value={formData.paymentDate}
      onChange={handleChange}
      required
      max={getCurrentDate()}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
    />
  </div>
</div>

            <div className="w-2/3 flex items-center space-x-4 p-4">
                <textarea 
                    className="flex-1 border border-gray-300 rounded p-2" 
                    rows="2" 
                    value={remark} 
                    onChange={(e) => setRemark(e.target.value)} 
                    placeholder="Enter your text here..."
                    required
                  
                ></textarea>                
                 <button
                                onClick={handleApproveAllVouchers}
                                className="bg-green-500 text-white px-4 py-2 rounded"
                            >
                                Approve All Vouchers
                            </button>
            </div>
        </>
 

                                
                        </div>
                    </TEModalBody>

                    <TEModalFooter>

                  
                        <TERipple rippleColor="light">
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={() => handleClosePopup()}
                            >
                                Close
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    );
};

export default VoucherPopupbatchApproved;
