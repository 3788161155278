import React, { useState, useEffect } from "react";
import Sidemenu from "../component/Sidemenu";
import axios from "axios";
import { toast } from "react-toastify";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { updateState } from '../redux-toolkit/slice/authSlice';
import edit from "../Images/edit.png";
import sidemenuicon from "../Images/sidemenuicon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const UpdateProfileUser = () => {
    const navigate = useNavigate();
    const dispatchevent = useDispatch();
    const userData = useSelector((state) => state.auth.user);

    const [refreshList, setRefresheList] = useState(0);
    const [company, setCompany] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);

    const [fieldsEnabled, setFieldsEnabled] = useState({
        userName: false,
        email: false,
        mobileNo: false,
        companyId: false,
        role: false,
        existingPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const initialFormData = {
        existingPassword: "",
        newPassword: "",
        mobile: userData.mobileNo,
        profilePicAttachment: "",
        userId: userData.userId,
        CompanyId: userData.companyId || '0'
    };

    const [formData, setFormData] = useState(initialFormData);

    const fetchCompany = async () => {
        try {
            const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
            setCompany(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setLoading(false);
        }
    };

    const extractFileName = (path) => {
        return path.split('\\').pop();
    };

    const profilePicFileName = userData?.profilePic ? extractFileName(userData.profilePic) : null;
    const profilePicUrl = profilePicFileName ? `https://expenseapi.sarahtech.com/api/Files/viewprofilepic/${encodeURIComponent(profilePicFileName)}` : sidemenuicon;
console.log('profilePicUrl', profilePicUrl)
    const handleFileChange = (e) => {
        debugger
        const file = e.target.files[0];
        setFormData({
            ...formData,
            profilePicAttachment: file,
        });
        console.log('File data uploaded', file);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        setFormData((prevState) => ({
            ...prevState,
            newPassword: e.target.value,
        }));
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordMatch(e.target.value === newPassword);
    };

    const handleSubmit = async () => {
        if (!passwordMatch) {
            toast.error("New passwords do not match");
            return;
        }

        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach((key) => {
                formDataToSend.append(key, formData[key]);
            });

            const response = await axios.put(
                'https://expenseapi.sarahtech.com/api/NewUser/update_user_profile',
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
           

            if (response.data.statusCode === 200) {
                toast.success(response.data.status);
                
                // Dispatch the event and navigate to the update profile page
                dispatchevent(updateState(response?.data.response));
                navigate('/UpdateProfileUser');
              } else {
                toast.error(response.data.status, {
                  className: 'toast-error', // Custom class name for styling
                });
              }

            
           
               
          
              
            

            document.getElementById('profilePicAttachment').value = '';
            setRefresheList(refreshList + 1);
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data);
            } else if (error.request) {
                toast.error('No response received from the server');
            } else {
                toast.error('An error occurred while processing your request');
            }
        }
    };

    useEffect(() => {
        fetchCompany();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleEnableField = (field) => {
        setFieldsEnabled((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    const getHref = () => {
        if (userData.role === 'Admin') {
            return '/DashboardAdmin';
        } else if (userData.role === 'Accountant') {
            return '/DashboardAccount';
        } else if (userData.role === 'User') {
            return '/Dashboard';
        }
        return '#'; // Default href if no role matches
    };

    return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                <a href={getHref()} className="flex items-center space-x-2">
          <BiSolidLeftArrowAlt />
      </a>
                </div>
                <div className="flex flex-row">
                    <div className="Col bg-white mx-2 my-2 md:w-1/2">
                        <div className="flex flex-col md:flex-col bg-white mx-2 my-2">
                            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                <h2 className="mt-6 text-center text-3xl font-poppins text-gray-900">
                                    <div className="bg-opacity-50 p-4 rounded-xl flex flex-col items-center space-y-4">
                                        <div className="bg-opacity-50 p-2 rounded-xl flex items-center justify-center space-x-10">
                                            <div className="w-24 h-24">
                                                <img
                                                    src={profilePicUrl}
                                                    alt="User Icon"
                                                    className={`w-full h-full rounded-full shadow-lg border-1 border-white cursor-pointer transition-transform duration-300 object-cover`}
                                                />
                                            </div>
                                            <div className="flex flex-col items-center">
                                            <div className="flex justify-center">
    <button
        type="button"
       className="px-4 py-2 bg-blue-500 text-sm md:text-lg text-white rounded-md hover:bg-blue-600"
        onClick={() => document.getElementById('profilePicAttachment').click()}
    >
        Change Picture
    </button>
    <input
        className="hidden"
        id="profilePicAttachment"
        name="profilePicAttachment"
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={handleFileChange}
    />
</div>


                                            </div>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                            <div className="flex flex-col md:flex-row justify-center">
                                <div className="w-full md:max-w-lg max-w-64 md:mt-20 md:mx-8 mx-8 my-2">
                                    {/* Profile Name */}
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-2/3">
                                            <label htmlFor="ProfileName" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                Name
                                            </label>
                                        </div>
                                        <div className="md:w-2/3 flex">
                                            <input
                                                id="userName"
                                                name="userName"
                                                type="text"
                                                value={userData.userName}
                                                onChange={handleChange}
                                                disabled={!fieldsEnabled.userName}
                                                className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.userName ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                                            />
                                     
                                        </div>
                                        <button
                                                type="hidden"
                                                className="ml-2 p-1 bg-gray-300 rounded invisible"
                                                onClick={() => handleEnableField('mobileNo')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                    </div>

                                    {/* Email */}
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-2/3">
                                            <label htmlFor="email" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                Email
                                            </label>
                                        </div>
                                        <div className="md:w-2/3 flex">
                                            <input
                                                id="email"
                                                name="email"
                                                type="text"
                                                value={userData.email}
                                                onChange={handleChange}
                                                disabled={!fieldsEnabled.email}
                                                className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.email ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                                            />
                                        
                                        </div>
                                        <button
                                                type="hidden"
                                                className="ml-2 p-1 bg-gray-300 rounded invisible"
                                                onClick={() => handleEnableField('mobileNo')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                    </div>

                                    {/* Mobile No */}
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-2/3">
                                            <label htmlFor="mobileNo" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                Mobile No
                                            </label>
                                        </div>
                                        <div className="md:w-2/3 flex">
                                            <input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                value={formData.mobile}
                                                onChange={handleChange}
                                                disabled={!fieldsEnabled.mobileNo}
                                                className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.mobileNo ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                                            />
                                          
                                        </div>
                                        
                                        <button
                                                type="button"
                                                className="ml-2 p-1 bg-gray-300 rounded"
                                                onClick={() => handleEnableField('mobileNo')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                    </div>

                                    {/* Company */}
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-2/3">
                                            <label htmlFor="CompanyId" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                Company
                                            </label>
                                        </div>
                                        <div className="md:w-2/3 flex">
                                            <select
                                                id="CompanyId"
                                                name="CompanyId"
                                                value={formData.CompanyId}
                                                onChange={handleChange}
                                                disabled={!fieldsEnabled.companyId}
                                                className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.companyId ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                                            >
                                                <option value="0">Select</option>
                                                {company.map((comp) => (
                                                    <option key={comp.companyId} value={comp.companyId}>
                                                        {comp.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                       
                                        </div>
                                        <button
                                                type="hidden"
                                                className="ml-2 p-1 bg-gray-300 rounded invisible"
                                                onClick={() => handleEnableField('mobileNo')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                    </div>

                                    {/* Existing Password */}
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-2/3">
                                            <label htmlFor="existingPassword" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                Existing Password
                                            </label>
                                        </div>
                                        
                                        <div className="md:w-2/3 flex">
                                       
    <input
        id="existingPassword"
        name="existingPassword"
        // type={showPassword ? "text" : "password"}
        value={formData.existingPassword}
        onChange={handleChange}
        disabled={!fieldsEnabled.existingPassword}
        className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.existingPassword ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
    />



                                   
                                           
                                        </div>
                                        <button
                                                type="button"
                                                className="ml-2 p-1 bg-gray-300 rounded"
                                                onClick={() => handleEnableField('existingPassword')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                    </div>

                                    {/* Conditional Rendering of New and Confirm Password Fields */}
                                    {fieldsEnabled.existingPassword && (
                                        <>
                                            {/* New Password */}
                                            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                                <div className="md:w-2/3">
                                                    <label htmlFor="newPassword" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                        New Password
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3 flex">
                                                <div className="relative md:w-full">
                                                    <input
                                                        id="newPassword"
                                                        name="newPassword"
                                                        type={showPassword ? "text" : "password"}
                                                        value={newPassword}
                                                        onChange={handleNewPasswordChange}
                                                        // disabled={!fieldsEnabled.newPassword}
                                                        className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.newPassword ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                                                    />
                                                
                                                        <button
        type="button"
        className="absolute inset-y-0 right-0 flex items-center px-3 bg-gray-300 rounded"
        onClick={() => setShowPassword(!showPassword)}
    >
        {/* Add your icon or text here */}
        {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
    
                                                </div>
                                          
                                                </div>
                                                <button
                                                type="hidden"
                                                className="ml-2 p-1 bg-gray-300 rounded invisible"
                                                onClick={() => handleEnableField('mobileNo')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                            </div>

                                            {/* Confirm Password */}
                                            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                                <div className="md:w-2/3">
                                                    <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                                                        Confirm Password
                                                    </label>
                                                </div>
                                                <div className="md:w-2/3 flex">
                                                    <input
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        // type={showPassword ? "text" : "password"}
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}
                                                        // disabled={!fieldsEnabled.confirmPassword}
                                                        className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.confirmPassword ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                                                    />
                                                </div>
                                                <button
                                                type="hidden"
                                                className="ml-2 p-1 bg-gray-300 rounded invisible"
                                                onClick={() => handleEnableField('mobileNo')}
                                            >
                                                <img
                                                    src={edit}
                                                    alt="Logo"
                                                    className="h-6"
                                                />
                                            </button>
                                                
                                            </div>
                                        </>
                                    )}

                                    {/* Submit Button */}
                                    <div className="flex justify-center mt-8">
                                        <button
                                            type="button"
                                            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                            onClick={handleSubmit}
                                        >
                                            Update Profile
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProfileUser;
