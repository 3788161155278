import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import Table from "../component/Table";
import UserAnalytics from "../component/UserAnalytics";
import TravelTempTable from "../component/TravelTempTable";
import { useNavigate } from "react-router-dom";

import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';






const AdminTravekVoucherFill = () => {
  
  const [showModal, setShowModal] = useState(false);
  const [isFood, setIsFood] = useState(false)
  const [isFuel, setIsFuel] = useState(false)
  const [isTravel, setIsTravel] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isParking, setIsParking] = useState(false)
  const [showFields, setShowFields] = useState(false);
  const [userTempVoucherData, setUserTempVoucherData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');

  const [loading, setLoading] = useState(true);
  const[bank, setBank] = useState([])
  const [locations, setLocations] = useState([])

  const[paymentTypes, setPaymentTypes] = useState([])
  const[paymentMode, setPaymentMode] = useState([])
  const [company, setCompany] = useState([])
  const [data, setData] = useState(false)
  const [isMisc, setIsMisc] = useState(false)
  const [expenseSubType, setExpenseSubTypeFor] = useState([]);
  const [vehicle, setVehicle] = useState(false)

  const [tableData, setTableData] = useState([]);
  const [attachment, setAttachment] = useState(null); // State to track attachment
  const [checkboxChecked, setCheckboxChecked] = useState(false); // State to track checkbox
  const [errorMessage, setErrorMessage] = useState('');
  const [refreshList, setRefresheList] = useState(0)
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [eventIdVoucher, setEventIdVoucher] = useState(0);

  const defaultValue = 0;



  const [formData, setFormData] = useState({
    OfficialPersonal:'',
    ExpenseType: '',
    SubType: '',
    ExpenseDate: '',
    ExpenseAmount: '',
    Attachments: '',
    attachmentPath: "",
    Description: '',
    VehicleType:'',
    FromSource: '',
    ToSource: '',
    DistanceTravelled: '',
    NoOfPerson:'',
    FromDate: '',
    ToDate: '',
    ApplyDate: '0000-00-00 00:00:00.000',
    AdminRemark: '',
    UserId: null,
    FilledByUserId: 'null',
    AttachmentCondition: '',
    HotelName: '',
    Location: '',
    AdminId:'null',
    ExpenseName:'',
    DonationFor: '',
    DonationType: '',
    eventId:null,
    PaymentType:'',
    LocationId: '',

    CompanyId:'',
    BankId:''

  });

  const navigate = useNavigate();

  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  console.log(actionId);
  useEffect(()=>{setEventIdVoucher(actionId)},[eventIdVoucher]);


  const fetchVehicle = async () => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_vehicle_mater');
      setVehicle(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchVehicle();
  }, []);

  const fetchCompany = async () => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
      setCompany(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setLoading(false);
    }
  };


  const handleGetSubExpenseAlongwithExpenseType = async () => {
    debugger
    try {
      // Make the API call
      const response = await axios.get('https://expenseapi.sarahtech.com/api/Accounts/get_all_expense_sub_type_along_with_expense_type');
      console.log('Response of expense type along with sub expense type', response.data.listofExpenseAndSubExpense);
  
      // Extract the list of expense and sub expense
      const { listofExpenseAndSubExpense } = response.data;
  
      // Define a variable to hold the filtered data
      let filteredData;
  
      // Check the formData.OfficialPersonal value and apply the corresponding filter
  
        filteredData = listofExpenseAndSubExpense.filter(item => item.expenseId === 2 );
      
  
      // Update the state with the filtered data
      setExpenseSubTypeFor(filteredData);
      console.log('test filter',filteredData )
  
    } catch (error) {
      // Log any errors
      console.error('Error handling get subexpense and expense type', error);
    }
  };
  
  

  useEffect(() => {
    handleGetSubExpenseAlongwithExpenseType();
  }, [formData.OfficialPersonal]);

  const fetchUserTempVoucherData = async () => {
    const eventId = actionId;
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Events/get_journey_expense_voucher_temp/${eventId}/${userId}`;

    try {
        const response = await axios.get(url);
        const data = response.data.data; // Adjust based on your actual API response
        setUserTempVoucherData(data);
        console.log('User Expense Data temp voucher', data);
    } catch (err) {
        console.log('User Expense Data Error', err.response.data);
    }
};

//   const fetchExpenseSubType = () => {
//     const url = `http://172.10.10.61:94/api/Master/get_expense_sub_type?expenseTypeId=${1}`;
//     axios.get(url)
//       .then((response) => {
//         console.log('Expense SubType Data', response.data);
//         setExpenseSubTypeFor(response.data);
//       })
//       .catch((err) => {
//         console.log('Response Error', err.response.data);
//         toast.error("Failed to load expense subtypes.");
//       });
//   }


const fetchEventData = async () => {
  const eventId = actionId;
  const url = `https://expenseapi.sarahtech.com/api/Events/get_event_details_by_event_id/${eventId}`;
  try {
    const response = await axios.get(url);
    console.log('evntid data', response);

   

    // Ensure data is an array and get the first event
    const data = response.data.data;
    const firstEvent = data.length > 0 ? data[0] : null;

    if (firstEvent) {
      // Extract and format the required fields
      const eventName = firstEvent.eventName || 'No Name provided';
      const task = firstEvent.task || 'No task provided';
      const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;
      const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;
      const fromDate = firstEvent.fromDate ? firstEvent.fromDate.split("T")[0] : null;
      const toDate = firstEvent.toDate ? firstEvent.toDate.split("T")[0] : null;

      const trimmedData = {
        task,
        entryDate,
        eventName,
        expenseTypeId,
        fromDate,
        toDate
      };

      console.log('Event Data:', trimmedData);
      setData(trimmedData);
      return trimmedData;
    } else {
      console.log('No event data found');
      return null;
    }
  } catch (err) {
    toast.error("Failed to Load Expense Voucher.");
  }
};



const fetchPaymentTypes = async () => {
  try {
    const url = 'https://expenseapi.sarahtech.com/api/MasterApi/get_payment_type';
    const response = await axios.get(url);
    setPaymentMode(response.data);
    // toast.success("Payment types loaded successfully.");
  } catch (error) {
    console.error('Error fetching payment types:', error);
    toast.error("Failed to load payment types.");
  }
};


  const fetchUserData = async () => {
    try {
      const userId = userData.userId;
      
      const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_id/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // Trim time part from date strings
      const trimmedData = data.map(item => {
        return {
          ...item,
          expenseDate: item.expenseDate.split("T")[0], // Trim time part
          applyDate: item.applyDate.split("T")[0] // Trim time part
        };
      });
      console.log('table Data ', trimmedData);
      setTableData(trimmedData);
      return trimmedData;
    } catch (error) {
      console.error('Error fetching data:', error);
      return []; // Return empty array in case of error
    }
  };



  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setAttachment(selectedFiles);
  
  
    // Limit to 3 files and show alert if exceeded
    if (selectedFiles.length > 3) {
      alert("You can only upload up to 3 files.");
      selectedFiles.splice(3); // Trim the array to the first 3 files if more than 3 are selected
    }
  
    // Update formData with the trimmed list of files
    setFormData((prevFormData) => ({
      ...prevFormData,
      Attachments: selectedFiles,
    }));
  
    // Log files to verify the update
    console.log("Files uploaded:", selectedFiles);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCheckboxChecked(isChecked);
    setFormData((prevData) => ({
      ...prevData,
      AttachmentCondition: isChecked ? 'Yes' : 'No'
    }));
  };


  const handleSubmit = async (e) => {
    if (!formData.Attachments?.length && !checkboxChecked) {
      setErrorMessage('Either attach a file or check the box to confirm no attachment is required.');
      return; // Prevent form submission if validation fails
    }
    debugger
    setFormData({
      ...formData,
      UserId: userData.userId,
      FilledByUserId: userData.userId,
      AdminId: userData.userId,
      eventId: eventIdVoucher
    });
    console.log('User Data', userData);

    e.preventDefault();
    setShowModal(true)


  };

  useEffect(() => {
    console.log('form data changed: ', formData);
  }, [formData]);

  const handleSendData = async () => {


    try {

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const hotelDetails = `${formData.HotelName}, ${formData.Location}`;
  
      // Prepare FormData object to handle file attachments for the API
      const formDataToSend = new FormData();
  
      // Append all non-file fields to formDataToSend
      formDataToSend.append('ApplyDate', formattedDate);
      formDataToSend.append('HotelDetails', hotelDetails);
  
      // Add all other form fields dynamically
      for (const key in formData) {
        if (key !== 'Attachments' && formData[key] !== null && formData[key] !== undefined) {
          formDataToSend.append(key, formData[key]);
        }
      }
  
      // Add attachments if they exist
      if (formData.Attachments && formData.Attachments.length > 0) {
        formData.Attachments.forEach((file) => {
          formDataToSend.append('Attachments', file);
        });
      }
  



      const response = await axios.post(
        'https://expenseapi.sarahtech.com/api/Admin/add_journey_expense_voucher_admin_temp',
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response:', response.data);
      toast.success(response.data.status);
      setFormData({
        OfficialPersonal:'',
    ExpenseType: '',
    SubType: '',
    ExpenseDate: '',
    ExpenseAmount: '',
    Attachments: '',
    attachmentPath: "",
    Description: '',
    VehicleType:'',
    FromPlace: '',
    ToPlace: '',
    DistanceTravelled: '',
    NoOfPerson:'',
    FromDate: '',
    ToDate: '',
    ApplyDate: '0000-00-00 00:00:00.000',
    AdminRemark: '',
    UserId: null,
    AttachmentCondition: '',
    FilledByUserId: 'null',
    HotelName: '',
    Location: '',
    AdminId:'null',
    ExpenseName:'',
    DonationFor: '',
    DonationType: '',
    eventId:null,
    PaymentType:'',
    LocationId: '',

    CompanyId:'',
    BankId:''
      });
      document.getElementById('document').value = '';
      setRefresheList(refreshList+1);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {

        toast.error(error.response.data);
      } else if (error.request) {

        toast.error('No response received from the server');
      } else {

        toast.error('An error occurred while processing your request');
      }
    }
  }

  const FinalSubmit = async () => {
    try {
        console.log('Sending User final Data', formData);
     

        // Simplified payload with only userId and eventId
        const payload = {
          
            userId: userData.userId, // Assuming formData contains UserId
            eventId: formData.eventId // Assuming formData contains eventId
        };

        const response = await axios.post(
            'https://expenseapi.sarahtech.com/api/Events/final_submit_journey_expense',
            payload,
            {
                headers: {
                    'Content-Type': 'application/json', // Changed to JSON as it's a simple payload
                },
            }
        );
        console.log('Response final submit:', response.data);
        toast.success(response.data.status);
       
          navigate('/DashboardAdmin');
      

        // Reset formData if necessary
        setFormData({
          ExpenseType: '',
    SubType: '',
    ExpenseDate: '',
    ExpenseAmount: '',
    Attachment: '',
    attachmentPath: "",
    Description: '',
    VehicleType:'',
    FromPlace: '',
    ToPlace: '',
    DistanceTravelled: '',
    NoOfPerson:'',
    FromDate: '',
    ToDate: '',
    ApplyDate: '0000-00-00 00:00:00.000',
    AdminRemark: '',
    UserId: null,
    FilledByUserId: 'null',
    AttachmentCondition: '',
    HotelName: '',
    Location: '',
    AdminId:'null',
    ExpenseName:'',
    DonationFor: '',
    DonationType: '',
    eventId:null,
    PaymentType:'',
    LocationId: '',

    CompanyId:'',
    BankId:''
      });
        document.getElementById('document').value = '';
    } catch (error) {
        console.error('Error:', error);
        if (error.response) {
            toast.error(error.response.data);
        } else if (error.request) {
            toast.error('No response received from the server');
        } else {
            toast.error('An error occurred while processing your request');
        }
    }
};
 
useEffect(() => {
  setFormData(prevState => ({
    ...prevState,
    eventId: eventIdVoucher
  }));

  console.log('User Voucher Fill EventIdVoucher', eventIdVoucher)
}, [eventIdVoucher, refreshList]);
const handleRadioChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value
  });
  setSelectedOption(value);
};

  const handleChange = (e) => {
    console.log('handle change', e.target.value, e.target.name);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
 



    if (name === 'SubType' && value === 'FOOD EXPENSE') {
        setIsFuel(false);
        setIsTravel(false);
        setIsFood(true);
        setIsMisc(false);
        setIsHotel(false);

        console.log('set is FOOD', isFood);
      }

      if (name === 'SubType' && value === 'HOTEL EXPENSE') {
        setIsFuel(false);
        setIsMisc(false);
        setIsFood(true);
        setIsHotel(true);
      }

    if (name === 'SubType' && value === 'FUEL EXPENSE') {
        setIsFuel(true);
        setIsHotel(false);
        setIsMisc(false);
        setIsFood(false);

    }

    if (name === 'SubType' && value === 'PARKING') {
        setIsHotel(false);
        setIsFood(false);
      setIsFuel(false);
      setIsMisc(false);

    }

    if (name === 'SubType' && value === 'TOLL') {
        setIsHotel(false);
        setIsFood(false);
      setIsFuel(false);
      setIsMisc(false);

      console.log('set is Travel', isTravel);
    }

    if (name === 'SubType' && value === 'MISCELLANEOUS EXPENSE') {
        setIsHotel(false);
        setIsFood(false);
      setIsFuel(false);
        setIsMisc(true);
        console.log('set is Travel', isTravel);
      }
      if (name === 'paymentModes') {
        if (value === 'Paid') {
          setShowFields(true);
          setFormData((prevFormData) => ({
            ...prevFormData,
            IsPaid: true,
          }));
        } else if (value === 'Unpaid') {
          setShowFields(false);
          setFormData((prevFormData) => ({
            ...prevFormData,
            IsPaid: false,
          }));
        }
      }

      if (name === 'CompanyId') {

     

          const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;
      
          axios.get(url)
            .then((response) => {
              console.log("bank: ", response.data);
              setBank(response.data);
              // Uncomment the line below if you want to show a success message to the user
              // toast.success("Expense subtypes loaded successfully.");
            })
            .catch((err) => {
              console.error('Response Error: ', err.response ? err.response.data : err.message);
              toast.error("Failed to load expense subtypes.");
            });
      }

      if (name === 'CompanyId') {

        const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_company_location_details_by_company_id/${value}`;
  
        axios.get(url)
          .then((response) => {
            console.log("location: ", response.data);
            setLocations(response.data);
            // Uncomment the line below if you want to show a success message to the user
            // toast.success("Expense subtypes loaded successfully.");
          })
          .catch((err) => {
            console.error('Response Error: ', err.response ? err.response.data : err.message);
            toast.error("Failed to load expense subtypes.");
          });
  
  
  
  
  
  
          
      }
      if (name === 'BankId') {
        debugger

     

        const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;
    
        axios.get(url)
          .then((response) => {
            console.log("Pay Type: ", response.data);
            setPaymentTypes(response.data);
            // Uncomment the line below if you want to show a success message to the user
            // toast.success("Expense subtypes loaded successfully.");
          })
          .catch((err) => {
            console.error('Response Error: ', err.response ? err.response.data : err.message);
            toast.error("Failed to load expense subtypes.");
          });
    }
  };


  useEffect(() => {
  }, [isFuel]);

  useEffect(() => {
    console.log('set is Travel', isTravel);
  }, [isTravel]);

  useEffect(() => {

     fetchUserTempVoucherData();
  }, [refreshList]);
  useEffect(() => {
    fetchPaymentTypes();
     fetchCompany();
  }, []);




  useEffect(() => {
    console.log('store data of User', userData);
    console.log('store data of User', isLoggedIn);
    fetchUserData();
    fetchEventData();
  }, [refreshList]);

 return (
    <div className="h-screen flex bg-[#F2FAFF]">
    <div className="p-2">
      <Sidemenu />
    </div>
    <div className="w-full flex flex-col overflow-y-auto">
      <div className="w-full h-12 flex items-center justify-start mt-12">
      <a href="/Dashboard" className="flex items-center space-x-2">
                      <BiSolidLeftArrowAlt />
                  </a>
                  <p className="text-gray-800 font-poppins ml-2">{data.eventName
 ? data.eventName
 : "No task provided"}</p>
      </div>
      <div className="flex flex-row bg-white mx-2 py-4">
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            {/* Content on the left side */}
            <h1 className="text-xl font-bold mb-1 mx-2">Event Description:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.task ? data.task : "No task provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">From Date:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.fromDate ? data.fromDate : "No date provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">To Date:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.toDate ? data.toDate : "No date provided"}</p>
          </div>
        </div>



        {/* form */}
        <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center h-screen">

<div className="md:flex md:items-center 3xl:mb-6 mt-3 gap-3">
  <div className="md:flex md:items-center 3xl:mb-6 mb-1">
    <div className="flex">
      <input
        type="radio"
        id="Official"
        name="OfficialPersonal"
        value="Official"
        checked={selectedOption === 'Official'}
        onChange={handleRadioChange}
        className="mr-2"
      />
      <label htmlFor="official" className="text-gray-700">Official</label>
    </div>
  </div>
  <div className="md:flex md:items-center 3xl:mb-6 mb-1">
    <div className="flex">
      <input
        type="radio"
        id="Personal"
        name="OfficialPersonal"
        value="Personal"
        checked={selectedOption === 'Personal'}
        onChange={handleRadioChange}
        className="mr-2"
      />
      <label htmlFor="personal" className="text-gray-700">Personal</label>
    </div>
  </div>
</div>
<div className="w-3/4 flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-1">
  {/* Left section */}
  <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-1">


  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
      <div className="md:w-1/3">
        <label
          htmlFor="Sub Type"
          className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
          Expense Type:
        </label>
      </div>
      <div className="md:w-2/3">
        <select
          id="SubType"
          name="SubType"
          value={formData.SubType}
          onChange={(e) => {
            const selectedSubType = e.target.value;
            const selectedSubTypeData = expenseSubType.find(item => item.expenseSubType === selectedSubType);
            const selectedExpenseType = selectedSubTypeData ? selectedSubTypeData.expenseType : '';

            handleChange(e); // Call your handleChange function with the event
            setFormData({
              ...formData,
              SubType: selectedSubType, // Update SubType in formData
              ExpenseType: selectedExpenseType // Update ExpenseType in formData
            });
          }}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
        >
          <option disabled value="">
            Select
          </option>
          {expenseSubType.map((item) => (
            <option key={item.expenseSubTypeId} value={item.expenseSubType}>
              {item.expenseSubType} - {item.expenseType}
            </option>
          ))}
        </select>

      </div>
    </div>




    {/* <div className="md:flex md:items-center 3xl:mb-6 mb-3">
      <div className="md:w-1/3">
        <label
          htmlFor="ExpenseType"
          className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
          Expense Type
        </label>
      </div>
      <div className="md:w-2/3">
        <select
          id="ExpenseType"
          name="ExpenseType"
          value={formData.ExpenseType}
          onChange={handleChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
        >
          <option disabled value="">Select</option>
          {expenseTypes.map((item) => (
            <option key={item.expenseId} value={item.ExpenseName}>
              {item.ExpenseName}
            </option>
          ))}
        </select>
      </div>
    </div> */}


<div className="md:flex md:items-center mb-3 2xl:mb-8">
        <div className="md:w-1/3">
          <label
            htmlFor="Attachment"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
          >
            Attach up to 3 Images:
          </label>
        </div>
        <div className="md:w-2/3 flex">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="document"
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />
        </div>
        <div className="mt-4 flex">
  {Array.isArray(formData.Attachments) &&
    formData.Attachments.map((file, index) => (
      file.type === "application/pdf" ? (
        <div key={index} className="mr-2">
          <p>{file.name}</p>
        </div>
      ) : (
        <img
          key={index}
          src={URL.createObjectURL(file)}
          alt={`Attachment ${index + 1}`}
          className="w-20 h-20 mr-2 border rounded"
        />
      )
    ))}
</div>

      </div>

{!attachment && (
<div className="md:flex md:items-center mb-3">
  <div className="md:w-1/3"></div>
  <div className="md:w-2/3">
    <label className="block text-gray-700 text-sm font-poppins mb-1">
      <input
        type="checkbox"
        checked={checkboxChecked}
        onChange={handleCheckboxChange}
      />
      I confirm that no attachment is needed.
    </label>
  </div>
</div>
)}

{errorMessage && (
<div className="text-red-500 mb-3 text-sm">
  {errorMessage}
</div>
)}

       {isHotel && (
                <>

                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="From Date"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        From Date:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="date"
                        name="FromDate"
                        value={formData.FromDate}
                        onChange={handleChange}
                        required
                        max={getCurrentDate()}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Hotel Name"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Hotel Name:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Hotel Name"
                        name="HotelName"
                        value={formData.HotelName}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                </>

              )}
{isFuel && (
  <>

<div className="md:flex md:items-center mb-3 2xl:mb-6 ">
<div className="md:w-1/3">
  <label
    htmlFor=" From Source"
    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
  >
    From Source:
  </label>
</div>
<div className="md:w-2/3">
  <input
    type="text"
    placeholder="From Place"
    name="FromSource"
    value={formData.FromSource}
    onChange={handleChange}
    required
    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
  />
</div>
</div>

{/* <div className="md:flex md:items-center mb-3">
      <div className="md:w-1/3">
        <label
          htmlFor="Vehicle Type"
          className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
          Vehicle Type:
        </label>
      </div>
      <div className="md:w-2/3">
        <input
          type="text"
          placeholder="Vehicle Type"
          name="VehicleType"
          value={formData.VehicleType}
          onChange={handleChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
        />
      </div>
    </div> */}


<div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                    <label
                                                htmlFor="Vehicle Type"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Vehicle Type:
                                            </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="vehicleType"
                        name="vehicleType"
                        value={formData.vehicleType}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {vehicle.map((item) => (
                          <option key={item.vehicleId} value={item.vehicleType}>
                            {item.vehicleType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

  </>

)}

{isFood && (
                <>
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="No Of Person"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        No Of Person:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="Number"
                        placeholder="No Of Person."
                        name="NoOfPerson"
                        value={formData.NoOfPerson}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                </>

              )}

{isMisc && (
                <>
                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Expense Name"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Expense Name:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Expense Name"
                        name="ExpenseName"
                        value={formData.ExpenseName}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                </>

              )}
    

            </div>

   







            {/* right section */}
            <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-1">

              
<div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Expense Amount"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Amount:
                  </label>
                </div>

                <div className="md:w-2/3">
                  <input
                    type="tel"
                    placeholder="Expense Amount"
                    name="ExpenseAmount"
                    value={formData.ExpenseAmount}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  />
                </div>
              </div>

        

          

           
           

<div className="md:flex md:items-center mb-3 2xl:mb-6">
  <div className="md:w-1/3">
    <label
      htmlFor="Expense Date"
      className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      Expense Date:
    </label>
  </div>

  <div className="md:w-2/3">
    <input
      type="date"
      placeholder="Expense Date"
      name="ExpenseDate"
      value={formData.ExpenseDate}
      onChange={handleChange}
      required
      max={getCurrentDate()}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
    />
  </div>
</div>
         
      

        

        

         

              {isHotel && (
                <>

<div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="To Date"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        To Date:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="date"
                        name="ToDate"
                        value={formData.ToDate}
                        onChange={handleChange}
                        required
                        max={getCurrentDate()}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
               
                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Hotel Location"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Hotel Location:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Location"
                        name="Location"
                        value={formData.Location}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>




                </>

              )}


{isFuel && (
  <>


<div className="md:flex md:items-center mb-3 2xl:mb-6 ">
      <div className="md:w-1/3">
        <label
          htmlFor="To Source"
          className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
          ToSource :
        </label>
      </div>
      <div className="md:w-2/3">
        <input
          type="text"
          placeholder="Destination Place"
          name="ToSource"
          value={formData.ToSource}
          onChange={handleChange}
          required
          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
        />
      </div>
    </div>
<div className="md:flex md:items-center 3xl:mb-6 mb-3">
      <div className="md:w-1/3">
        <label
          htmlFor="Distance"
          className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
        >
          Distance:
        </label>
      </div>
      <div className="md:w-2/3">
        <input
          type="tel"
          placeholder="Distance in KMS."
          name="DistanceTravelled"
          value={formData.DistanceTravelled}
          onChange={handleChange}
          required
          className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
        />
      </div>
    </div>


 

  </>

)}


   
<div className="md:flex md:items-center 3xl:mb-6 mb-3">
<div className="md:w-1/3">
  <label
    htmlFor="paymentMode"
    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
  >
    Payment Mode
  </label>
</div>
<div className="md:w-2/3">
  <select
    id="paymentMode"
    name="paymentModes"
    value={formData.paymentModes}
    onChange={handleChange}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
  >
    <option value="">Select</option>
    {paymentMode.map((payment) => (
      <option key={payment.paymentId} value={payment.paymentName}>
        {payment.paymentName}
      </option>
    ))}
  </select>
</div>
</div>

              {/* Payment */}


            </div>


          </div>


{/* Payment */}

<div className="w-3/4 flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-1">
            {/* Left section */}
            <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-2">

       

{showFields && (
<>

<div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Company"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Company
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="Company"
                        name="CompanyId"
                        value={formData.CompanyId}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {company.map((item) => (
                          <option key={item.companyId} value={item.companyId}>
                            {item.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
<div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-1/3">
      <label
        htmlFor="Bank"
        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
      >
        Bank
      </label>
    </div>
    <div className="md:w-2/3">
      <select
        id="Bank"
        name="BankId"
        value={formData.BankId}
        onChange={handleChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
      >
        <option disabled value="">Select</option>
        {bank.map((item) => (
          <option key={item.bankId} value={item.bankId}>
            {item.bankName}
          </option>
        ))}
      </select>
    </div>
  </div>



</>

)}

            </div>

   







            {/* right section */}
            <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-2">

              
              {showFields && (
                <>
              
              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-1/3">
      <label
        htmlFor=" CompanyLocation"
        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
      >
        Company Location
      </label>
    </div>
    <div className="md:w-2/3">
      <select
        id="LocationId"
        name="LocationId"
        value={formData.LocationId}
        onChange={handleChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
      >
        <option disabled value="">Select</option>
        {locations.map((item) => (
          <option key={item.locationId} value={item.locationId}>
            {item.companyLocation}
          </option>
        ))}
      </select>
    </div>
  </div>
              
                
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="payment Type"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        payment Type
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="PaymentTypes"
                        name="PaymentType"
                        value={formData.PaymentType}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {paymentTypes.map((item) => (
                          <option key={item.paymentMasterId} value={item.paymentType}>
                            {item.paymentType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
       
              
                </>
              
              )}


            </div>


          </div>



          <div className="w-3/4 flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-1">
          <div className="w-2/3 bg-white md:mx-6 my-2 items-center justify-center 3xl:ml-8">
  <div className="md:flex md:items-center mb-6">
    <div className="md:w-1/3 3xl:w-1/5">
      <label
        htmlFor="Description"
        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
      >
        Description:
      </label>
    </div>
    <div className="md:w-full mr-16">
      <textarea
        placeholder="Description..."
        name="Description"
        value={formData.Description}
        onChange={handleChange}
        className="border border-gray-300 rounded-lg px-4 py-4 mr-2 w-full resize-none" // Add resize-none if you don't want the user to manually resize the textarea
        rows={1} // Initial number of rows
        style={{ overflow: 'hidden' }} // Hide the scroll bar
        onInput={(e) => {
          e.target.style.height = 'auto'; // Reset the height
          e.target.style.height = `${e.target.scrollHeight}px`; // Set it to the scroll height
        }}
      />
    </div>
  </div>
</div>
</div>

<div className="flex justify-center">
  <div className="my-2">
    <button
      onClick={handleSubmit}
      className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
    >
      Submit
    </button>
  </div>





  {/* Modal component */}
  <FormDetailsPopup formData={formData} showModal={showModal} setShowModal={setShowModal} handleSendData={handleSendData} />







</div>
</div>

      
    <TravelTempTable data={userTempVoucherData} />
        <div className="flex justify-center">
        <div className="my-2">
        <button className="bg-[#54BCE9] text-white px-4 py-2 rounded-xl mt-0 1/3"onClick={FinalSubmit}>Final Submit</button>


      </div>
      </div>


      </div>
    </div>

  )
}

export default AdminTravekVoucherFill