import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import { useNavigate } from "react-router-dom";
import ExpeTempTable from "../component/DraftTable";

import FormDetailsPopup from "../component/AdminFormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';






const DraftVoucerDetailsFill = () => {

  const [showModal, setShowModal] = useState(false);
  const [isFood, setIsFood] = useState(false)
  const [isFuel, setIsFuel] = useState(false)
  const [isTravel, setIsTravel] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isParking, setIsParking] = useState(false)
  const [showFields, setShowFields] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [bank, setBank] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [paymentMode, setPaymentMode] = useState([])
  const [company, setCompany] = useState([])
  const [data, setData] = useState(false)
  const [isMisc, setIsMisc] = useState(false)
  const [expenseSubType, setExpenseSubTypeFor] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [attachment, setAttachment] = useState(null);
  const [refreshList, setRefresheList] = useState(0)
  const [expenseTypes, setExpenseTypes] = useState([]);

  const defaultValue = 0;

  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    OfficialPersonal: '',
    ExpenseType: '',
    SubType: '',
    ExpenseDate: '',
    ExpenseAmount: '',
    Attachments: '' ,
    attachmentPath: "",
    description: '',
    VehicleType: '',
    FromSource: '',
    ToSource: '',
    DistanceTravelled: '',
    NoOfPerson: '',
    FromDate: '',
    ToDate: '',
    

    UserId: null,
    FilledByUserId: 'null',
    HotelName: '',
    Location: '',

    ExpenseName: '',

    PaymentType: '',
    CompanyId: '',
    BankId: ''

  });


  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);



  const fetchCompany = async () => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
      setCompany(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setLoading(false);
    }
  };


  // const fetchData = async () => {
  //   try {
  //     const response = await fetch('https://expenseapi.sarahtech.com/api/MasterApi/get_expense_type');
  //     if (response.ok) {
  //       const jsonData = await response.json();
  //       console.log("jsonData:", jsonData);

  //       // Apply filtering logic based on the OfficialPersonal field
  //       let filteredData;
  //       if (formData.OfficialPersonal === "Personal") {
  //         filteredData = jsonData.filter(item => item.expenseId === 15);
  //       } else {
  //         filteredData = jsonData.filter(item => item.expenseId !== 2 && item.expenseId !== 15);
  //       }

  //       setExpenseTypes(filteredData);
  //     } else {
  //       console.error('Failed to fetch data:', response.status);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };






  const fetchEventData = async () => {

    try {
      const userId = userData.userId;
      const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_user_events_Pending/${userId}`);

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();

      // Ensure data is an array and get the first event
      const firstEvent = data.length > 0 ? data[0] : null;

      if (firstEvent) {
        // Extract and format the required fields
        const eventName
          = firstEvent.eventName
          || 'No Name provided';

        const task = firstEvent.task || 'No task provided';
        const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;

        const trimmedData = {
          task,
          entryDate,
          eventName

        };

        console.log('Event Data:', trimmedData);
        setData(trimmedData);
        return trimmedData;
      } else {
        // Handle case when there are no events
        console.warn('No events found for the user.');
        return {}; // Return empty object if no events found
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return {}; // Return empty object in case of error
    }
  };



  const fetchPaymentTypes = async () => {
    try {
      const url = 'https://expenseapi.sarahtech.com/api/MasterApi/get_payment_type';
      const response = await axios.get(url);
      setPaymentMode(response.data);
      // toast.success("Payment types loaded successfully.");
    } catch (error) {
      console.error('Error fetching payment types:', error);
      toast.error("Failed to load payment types.");
    }
  };


  const fetchUserData = async () => {
    try {
      const userId = userData.userId;
  
      const response = await fetch(`https://expenseapi.sarahtech.com/api/NewFetures/get_all_data_by_user_id/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
  
      // Trim time part from date strings and handle the key typo
      const trimmedData = data.listOfDraftedExpenseVoucher.map(item => {
        return {
          ...item,
          draftDate: item.draftDate.split("T")[0], // Trim time part
          eventDate: item.eventDate.split("T")[0], // Trim time part
          eventName: item.eventNmae, // Correct the typo
        };
      });
  
      console.log('Table Data', trimmedData);
      setTableData(trimmedData);
      return trimmedData;
    } catch (error) {
      console.error('Error fetching data:', error);
      return []; // Return empty array in case of error
    }
  };
  



  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setAttachment(selectedFiles);
  
    // Limit to 3 files and show alert if exceeded
    if (selectedFiles.length > 3) {
      alert("You can only upload up to 3 files.");
      selectedFiles.splice(3); // Trim the array to the first 3 files if more than 3 are selected
    }
  
    // Update formData with the trimmed list of files
    setFormData((prevFormData) => ({
      ...prevFormData,
      Attachments: selectedFiles,
    }));
  
    // Log files to verify the update
    console.log("Files uploaded:", selectedFiles);
  };

  const handleSubmit = async (e) => {

    setFormData({
      ...formData,
      UserId: userData.userId,
      FilledByUserId: userData.userId,

    });
    console.log('User Data', userData);

    e.preventDefault();
    setShowModal(true)


  };

  const handleGetSubExpenseAlongwithExpenseType = async () => {

    try {
      // Make the API call
      const response = await axios.get('https://expenseapi.sarahtech.com/api/Accounts/get_all_expense_sub_type_along_with_expense_type');
      console.log('Response of expense type along with sub expense type', response.data.listofExpenseAndSubExpense);

      // Extract the list of expense and sub expense
      const { listofExpenseAndSubExpense } = response.data;

      // Define a variable to hold the filtered data
      let filteredData;

      // Check the formData.OfficialPersonal value and apply the corresponding filter
      if (formData.OfficialPersonal === "Personal") {
        filteredData = listofExpenseAndSubExpense.filter(item => item.expenseId === 15);
      } else {
        filteredData = listofExpenseAndSubExpense.filter(item => item.expenseId !== 2 && item.expenseId !== 15);
      }

      // Update the state with the filtered data
      setExpenseSubTypeFor(filteredData);
      console.log('test filter', filteredData)

    } catch (error) {
      // Log any errors
      console.error('Error handling get subexpense and expense type', error);
    }
  };



  useEffect(() => {
    handleGetSubExpenseAlongwithExpenseType();
  }, [formData.OfficialPersonal]);

  useEffect(() => {
    console.log('form data changed: ', formData);
  }, [formData]);

  const handleSendData = async () => {


    try {

      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const hotelDetails = `${formData.HotelName}, ${formData.Location}`;
  
      // Prepare FormData object to handle file attachments for the API
      const formDataToSend = new FormData();
  
      // Append all non-file fields to formDataToSend
      
      formDataToSend.append('ApplyDate', formattedDate);
      formDataToSend.append('HotelDetails', hotelDetails);
  
      // Add all other form fields dynamically
      for (const key in formData) {
        if (key !== 'Attachments' && formData[key] !== null && formData[key] !== undefined) {
          formDataToSend.append(key, formData[key]);
        }
      }
  
      // Add attachments if they exist
      if (formData.Attachments && formData.Attachments.length > 0) {
        formData.Attachments.forEach((file) => {
          formDataToSend.append('Attachments', file);
        });
      }


      const response = await axios.post(
        'https://expenseapi.sarahtech.com/api/NewFetures/draft_expense_voucher',
        formDataToSend,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response:', response.data);
      toast.success(response.data.status);

      navigate('/DraftVoucerDetailsFill');

      setFormData({
        OfficialPersonal: '',
        ExpenseType: '',
        SubType: '',
        ExpenseDate: '',
        ExpenseAmount: '',
        Attachments: '' ,
        attachmentPath: "",
        description: '',
        VehicleType: '',
        FromSource: '',
        ToSource: '',
        DistanceTravelled: '',
        NoOfPerson: '',
        FromDate: '',
        ToDate: '',
        
      
        UserId: null,
        FilledByUserId: 'null',
        HotelName: '',
        Location: '',
    
        ExpenseName: '',
    
        PaymentType: '',
        CompanyId: '',
        BankId: ''
      });

      setRefresheList(refreshList + 1);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {

        toast.error(error.response.data);
      } else if (error.request) {

        toast.error('No response received from the server');
      } else {

        toast.error('An error occurred while processing your request');
      }
    }
  }




  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setSelectedOption(value);
  };

  const handleChange = (e) => {
    console.log('handle change', e.target.value, e.target.name);
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });






    if (name === 'SubType' && value === 'FOOD EXPENSE') {
      setIsFuel(false);
      setIsTravel(false);
      setIsFood(true);
      setIsMisc(false);
      setIsHotel(false);

      console.log('set is FOOD', isFood);
    }

    if (name === 'SubType' && value === 'HOTEL EXPENSE') {
      setIsFuel(false);
      setIsMisc(false);
      setIsFood(true);
      setIsHotel(true);
    }

    if (name === 'SubType' && value === 'FUEL EXPENSE') {
      setIsFuel(true);
      setIsHotel(false);
      setIsMisc(false);
      setIsFood(false);

    }

    if (name === 'SubType' && value === 'PARKING') {
      setIsHotel(false);
      setIsFood(false);
      setIsFuel(false);
      setIsMisc(false);

    }

    if (name === 'SubType' && value === 'TOLL') {
      setIsHotel(false);
      setIsFood(false);
      setIsFuel(false);
      setIsMisc(false);

      console.log('set is Travel', isTravel);
    }

    if (name === 'SubType' && value === 'MISCELLANEOUS EXPENSE') {
      setIsHotel(false);
      setIsFood(false);
      setIsFuel(false);
      setIsMisc(true);
      console.log('set is Travel', isTravel);
    }


    if (name === 'paymentModes') {
      if (value === 'Paid') {
        setShowFields(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          IsPaid: true,
        }));
      } else if (value === 'Unpaid') {
        setShowFields(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          IsPaid: false,
        }));
      }
    }

    if (name === 'CompanyId') {



      const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;

      axios.get(url)
        .then((response) => {
          console.log("bank: ", response.data);
          setBank(response.data);
          // Uncomment the line below if you want to show a success message to the user
          // toast.success("Expense subtypes loaded successfully.");
        })
        .catch((err) => {
          console.error('Response Error: ', err.response ? err.response.data : err.message);
          toast.error("Failed to load expense subtypes.");
        });
    }


    if (name === 'BankId') {




      const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;

      axios.get(url)
        .then((response) => {
          console.log("Pay Type: ", response.data);
          setPaymentTypes(response.data);
          // Uncomment the line below if you want to show a success message to the user
          // toast.success("Expense subtypes loaded successfully.");
        })
        .catch((err) => {
          console.error('Response Error: ', err.response ? err.response.data : err.message);
          toast.error("Failed to load expense subtypes.");
        });
    }
  };


  useEffect(() => {
  }, [isFuel]);

  useEffect(() => {
    console.log('set is Travel', isTravel);
  }, [isTravel]);

  useEffect(() => {
    fetchPaymentTypes();
    fetchCompany();
  }, []);


  // useEffect(() => {
  //   fetchData();
  // }, [formData.OfficialPersonal]);




  useEffect(() => {
    console.log('store data of User', userData);
    console.log('store data of User', isLoggedIn);
    fetchUserData();
    fetchEventData();
  }, [refreshList]);

  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/DashboardAdmin" className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <p className="text-gray-800 font-poppins ml-2"></p>
        </div>




        {/* form */}
        <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center h-screen">

          <div className="md:flex md:items-center 3xl:mb-6 mt-3 gap-3">
            <div className="md:flex md:items-center 3xl:mb-6 mb-1">
              <div className="flex">
                <input
                  type="radio"
                  id="Official"
                  name="OfficialPersonal"
                  value="Official"
                  checked={selectedOption === 'Official'}
                  onChange={handleRadioChange}
                  className="mr-2"
                />
                <label htmlFor="official" className="text-gray-700">Official</label>
              </div>
            </div>
            <div className="md:flex md:items-center 3xl:mb-6 mb-1">
              <div className="flex">
                <input
                  type="radio"
                  id="Personal"
                  name="OfficialPersonal"
                  value="Personal"
                  checked={selectedOption === 'Personal'}
                  onChange={handleRadioChange}
                  className="mr-2"
                />
                <label htmlFor="personal" className="text-gray-700">Personal</label>
              </div>
            </div>
          </div>
          <div className="w-3/4 flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-1">
            {/* Left section */}
            <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-1">


              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Sub Type"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Type:
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="SubType"
                    name="SubType"
                    value={formData.SubType}
                    onChange={(e) => {
                      const selectedSubType = e.target.value;
                      const selectedSubTypeData = expenseSubType.find(item => item.expenseSubType === selectedSubType);
                      const selectedExpenseType = selectedSubTypeData ? selectedSubTypeData.expenseType : '';

                      handleChange(e); // Call your handleChange function with the event
                      setFormData({
                        ...formData,
                        SubType: selectedSubType, // Update SubType in formData
                        ExpenseType: selectedExpenseType // Update ExpenseType in formData
                      });
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {expenseSubType.map((item) => (
                      <option key={item.expenseSubTypeId} value={item.expenseSubType}>
                        {item.expenseSubType} - {item.expenseType}
                      </option>
                    ))}
                  </select>

                </div>
              </div>




              {/* <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="ExpenseType"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Type
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="ExpenseType"
                    name="ExpenseType"
                    value={formData.ExpenseType}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  >
                    <option disabled value="">Select</option>
                    {expenseTypes.map((item) => (
                      <option key={item.expenseId} value={item.ExpenseName}>
                        {item.ExpenseName}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}





<div className="md:flex md:items-center mb-3 2xl:mb-8">
        <div className="md:w-1/3">
          <label
            htmlFor="Attachment"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
          >
            Attach up to 3 Images:
          </label>
        </div>
        <div className="md:w-2/3 flex">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="document"
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />
        </div>
        <div className="mt-4 flex">
  {Array.isArray(formData.Attachments) &&
    formData.Attachments.map((file, index) => (
      file.type === "application/pdf" ? (
        <div key={index} className="mr-2">
          <p>{file.name}</p>
        </div>
      ) : (
        <img
          key={index}
          src={URL.createObjectURL(file)}
          alt={`Attachment ${index + 1}`}
          className="w-20 h-20 mr-2 border rounded"
        />
      )
    ))}
</div>

      </div>







              {isHotel && (
                <>

                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="From Date"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        From Date:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="date"
                        name="FromDate"
                        value={formData.FromDate}
                        onChange={handleChange}
                        required
                        max={getCurrentDate()}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Hotel Name"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Hotel Name:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Hotel Name"
                        name="HotelName"
                        value={formData.HotelName}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                </>

              )}
              {isFuel && (
                <>

                  <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                    <div className="md:w-1/3">
                      <label
                        htmlFor=" From Source"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        From Source:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="From Place"
                        name="FromSource"
                        value={formData.FromSource}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Vehicle Type"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Vehicle Type:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Vehicle Type"
                        name="VehicleType"
                        value={formData.VehicleType}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                </>

              )}

              {isFood && (
                <>
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="No Of Person"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        No Of Person:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="Number"
                        placeholder="No Of Person."
                        name="NoOfPerson"
                        value={formData.NoOfPerson}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                </>

              )}

              {isMisc && (
                <>
                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Expense Name"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Expense Name:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Expense Name"
                        name="ExpenseName"
                        value={formData.ExpenseName}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                </>

              )}


            </div>









            {/* right section */}
            <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-1">


              <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Expense Amount"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Amount:
                  </label>
                </div>

                <div className="md:w-2/3">
                  <input
                    type="tel"
                    placeholder="Expense Amount"
                    name="ExpenseAmount"
                    value={formData.ExpenseAmount}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  />
                </div>
              </div>








              <div className="md:flex md:items-center mb-3 2xl:mb-6">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Expense Date"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Date:
                  </label>
                </div>

                <div className="md:w-2/3">
                  <input
                    type="date"
                    placeholder="Expense Date"
                    name="ExpenseDate"
                    value={formData.ExpenseDate}
                    onChange={handleChange}
                    required
                    max={getCurrentDate()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                  />
                </div>
              </div>









              {isHotel && (
                <>

                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="To Date"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        To Date:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="date"
                        name="ToDate"
                        value={formData.ToDate}
                        onChange={handleChange}
                        required
                        max={getCurrentDate()}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Hotel Location"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Hotel Location:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Location"
                        name="Location"
                        value={formData.Location}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>




                </>

              )}


              {isFuel && (
                <>


                  <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="To Source"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        ToSource :
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Destination Place"
                        name="ToSource"
                        value={formData.ToSource}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Distance"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Distance:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="tel"
                        placeholder="Distance in KMS."
                        name="DistanceTravelled"
                        value={formData.DistanceTravelled}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>




                </>

              )}





              {/* Payment */}


            </div>


          </div>


          {/* Payment */}

          <div className="w-3/4 flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-1">
            {/* Left section */}
            <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-2">

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="paymentMode"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Payment Mode
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="paymentMode"
                    name="paymentModes"
                    value={formData.paymentModes}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  >
                    <option value="">Select</option>
                    {paymentMode.map((payment) => (
                      <option key={payment.paymentId} value={payment.paymentName}>
                        {payment.paymentName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {showFields && (
                <>


                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Bank"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Bank
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="Bank"
                        name="BankId"
                        value={formData.BankId}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {bank.map((item) => (
                          <option key={item.bankId} value={item.bankId}>
                            {item.bankName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>



                </>

              )}

            </div>









            {/* right section */}
            <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-2">


              {showFields && (
                <>



                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Company"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Company
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="Company"
                        name="CompanyId"
                        value={formData.CompanyId}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {company.map((item) => (
                          <option key={item.companyId} value={item.companyId}>
                            {item.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="payment Type"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        payment Type
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="PaymentTypes"
                        name="PaymentType"
                        value={formData.PaymentType}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {paymentTypes.map((item) => (
                          <option key={item.paymentMasterId} value={item.paymentType}>
                            {item.paymentType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                </>

              )}


            </div>


          </div>



          <div className="w-3/4 flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-1">
            <div className="w-2/3 bg-white md:mx-6 my-2 items-center justify-center 3xl:ml-8">
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3 3xl:w-1/5">
                  <label
                    htmlFor="Description"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Description:
                  </label>
                </div>
                <div className="md:w-full mr-16">
                  <textarea
                    placeholder="Description..."
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg px-4 py-4 mr-2 w-full resize-none" // Add resize-none if you don't want the user to manually resize the textarea
                    rows={1} // Initial number of rows
                    style={{ overflow: 'hidden' }} // Hide the scroll bar
                    onInput={(e) => {
                      e.target.style.height = 'auto'; // Reset the height
                      e.target.style.height = `${e.target.scrollHeight}px`; // Set it to the scroll height
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="my-2">
              <button
                onClick={handleSubmit}
                className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
              >
                Submit
              </button>
            </div>





            {/* Modal component */}
            <FormDetailsPopup formData={formData} showModal={showModal} setShowModal={setShowModal} handleSendData={handleSendData} />







          </div>
        </div>




        <ExpeTempTable data={tableData} />


      </div>
    </div>

  )
}

export default DraftVoucerDetailsFill