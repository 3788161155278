import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import formatDate from '../utilities/formatDate';

import { toast } from 'react-toastify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VoucherPopup from './VoucherPopup1';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery, useTheme, Box, IconButton, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const Table = ({ data }) => {
    
    const [showVouchers, setShowVouchers] = useState(false);
    const [showVoucher, setshowVoucher] = useState(false);
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [remark, setRemark] = useState('');
    const [attachmentData, setAttachmentData] = useState([]);
    const [openRemarkDialog, setOpenRemarkDialog] = useState(false);
    const [anchorUsersEl, setAnchorUsersEl] = useState(null);
    const [voucherData, setVoucherData] = useState({
        voucherId: null,
        expenseType: "",
        subType: "",
        expenseDate: "2024-05-23T00:00:00",
        expenseAmount: null,
        attachment: null,
        attachmentPath: "",
        description: "",
        applyDate: "2024-05-03T00:00:00",
        fromPlace: "",
        toPlace: "",
        distance: 0,
        fromDate: "1900-01-01T00:00:00",
        toDate: "1900-01-01T00:00:00",
        userId: 0,
        userName: "",
        role: "",
        status: "",
        entryBy: "",
        donationType: "",
        donationFor: "",
        adminActionDate: "1900-01-01T00:00:00",
        adminRemark: "",
        accountantActionDate: "1900-01-01T00:00:00",
        accountantRemark: ""
    });
 

    const handleMenuOpenUser = (event, userId) => {
        setAnchorUsersEl(event.currentTarget);
        setSelectedUserId(userId);
    };

    const handleMenuCloseUser = () => {
        setAnchorUsersEl(null);
        setSelectedUserId(null);
    };
    const handleGetVoucherData = async (voucherId) => {
        const voucherDetailsUrl = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;
        const attachmentDetailsUrl = `https://expenseapi.sarahtech.com/api/Accounts/get_Attachments_details_by_voucher_id/${voucherId}`;
      
        try {
          // Fetch voucher details first
          const voucherDetailsResponse = await axios.get(voucherDetailsUrl);
      
          // Set voucher details and show voucher UI
          setVoucherData(voucherDetailsResponse.data);
          setShowVouchers(true);
          console.log('Voucher Details:', voucherDetailsResponse.data);
      
          try {
            // Attempt to fetch attachments
            const attachmentDetailsResponse = await axios.get(attachmentDetailsUrl);
            setAttachmentData(attachmentDetailsResponse?.data);
            console.log('Attachment Details:', attachmentDetailsResponse.data);
          } catch (attachmentError) {
            // Handle attachment fetch error
            console.warn('No attachments found or error fetching attachments:', attachmentError?.response?.data || attachmentError.message);
            setAttachmentData(null); // Clear attachment data if needed
          }
        } catch (voucherError) {
          // Handle errors for voucher details
          console.error('Error fetching voucher details:', voucherError?.response?.data || voucherError.message);
          toast.error("Failed to Load Expense Voucher.");
        }
      };
    // const handleGetVoucherData = async (voucherId) => {
    //     debugger
    //     const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;
    //     axios.get(url)
    //         .then((response) => {
    //             setVoucherData(response.data);
    //             setShowVouchers(true);
    //         })
    //         .catch((err) => {
    //             toast.error("Failed to Load Expense Voucher.");
    //         });
    // }
    const handleAcceptAll = async () => {

        try {
          
        const data = {
            eventId: userVoucherData[0].eventId,
            userId: userVoucherData[0].userId,
            adminRemark: remark
        };

          const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/journey_all_approved_by_admin', data);
          toast.success("All entries accepted successfully.");
          setshowVoucher(false);
          setOpenRemarkDialog(false);
    
          console.log('Response Handle Accept Expense Vocher:', response.data);
        
          setRemark('');
    
        } catch (error) {
    
          console.error('Error:', error);
          setRemark('');
        }
      }


const handleRejectAll = async () => {

    if (!remark) {
        toast.error('Remark is required for rejection.');
        return;
    }

    try {
      
    const data = {
        eventId: userVoucherData[0].eventId,
        userId: userVoucherData[0].userId,
        adminRemark: remark
    };

      const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/journey_all_rejected_by_admin', data);
      toast.success("All entries accepted successfully.");
      setshowVoucher(false);
      setOpenRemarkDialog(false);

      console.log('Response Handle Accept Expense Vocher:', response.data);
    
      setRemark('');

    } catch (error) {

      console.error('Error:', error);
      setRemark('');
    }
  }
    const handleData = async () => {
        debugger
        const url = `https://expenseapi.sarahtech.com/api/Events/get_travel_event_details_history/${selectedUserId}/${TableData[0]?.eventId}`;
        try {
            const response = await axios.get(url);
            setUserVoucherData(response.data.data); // Assuming response structure has a `data` property
            setshowVoucher(true);
        } catch (err) {
            toast.error("Failed to Load Expense Voucher.");
        }
    };


    const TableData = data?.map((item, index) => ({
        ...item,
        sn: index + 1,
    }));

console.log('table data',TableData )
const columns = [
    {
        name: "sn",
        label: "SN",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => <div style={{ textAlign: 'center' }}>{tableMeta.rowIndex + 1}</div>
        }
    },
    {
        name: "userName",
        label: "User Name",
        options: {
            customBodyRender: (value) => (
                <div style={{ textAlign: 'center' }}>{value}</div>
            )
        }
    },
    {
        name: "userAmount",
        label: "Amount Expense",
        options: {
            customBodyRender: (value) => (
                <div style={{ textAlign: 'center' }}>{value ? value : 'Not Applied'}</div>
            )
        },
    },
    {
        name: "userId",
        label: "Action",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => (
                <div style={{ textAlign: 'center' }}>
                    <IconButton onClick={(event) => handleMenuOpenUser(event, value, tableMeta.rowData[1])}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorUsersEl}
                        keepMounted
                        open={Boolean(anchorUsersEl) && selectedUserId === value}
                        onClose={handleMenuCloseUser}
                    >
                        <MenuItem onClick={() => handleData()}>Details</MenuItem>
                    </Menu>
                </div>
            )
        }
    }
];

    const detailedUserColumns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => <div style={{ textAlign: 'center' }}>{tableMeta.rowIndex + 1}</div>
            }
        },
        {
            name: "expenseSubType",
            label: "Expense",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{value}</div>
            }
        },
        {
            name: "expenseAmount",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{value} ₹</div>
            }
        },
        {
            name: "expenseDate",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "eventEntryDate",
            label: "Apply Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-center ${value === "Accepted" && "text-yellow-500"} ${value === "Approved" && "text-green-500"} ${value === "Pending" && "text-orange-500"} ${value === "Rejected" && "text-red-600"}`}>
                        {value}
                    </p>
                ),
                filter: true,
                sort: true,
            }
        },
        {
            name: "voucherId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <button style={{ fontSize: 'inherit', fontFamily: 'inherit' }} className='capitalize px-5 py-1 inline-block text-center cursor-pointer text-blue-500' onClick={() => { handleGetVoucherData(value); setShowVouchers(!showVouchers) }}>View</button>
                )
            }
        }
    ];
    


    const options = {
        selectableRows: 'none',
        elevation: 0,
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto',
        pagination: false, 
        print: false, // Hide print option
        search: false, // Hide search option
        download: false, // Hide download option
        viewColumns: false, // Hide view columns option
        filter: false, // Hide filter option
        sort: false, // Disable sorting
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "center", // Center align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });
    


    const handleOpenRemarkDialog = () => {
        setOpenRemarkDialog(true);
    };

    const handleCloseRemarkDialog = () => {
        setOpenRemarkDialog(false);
    };
    const CustomTitle = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 className='text-bold'><strong>Travel Eligible List</strong></h1>
            <div ><strong>Total Expense Amount in Travel:</strong> {TableData && TableData[0] && TableData[0].totalAmount}</div>
        </div>
    );


    return (
        <>        
            <div className='py-5 min-h-screen flex justify-center items-center'>
                <Box width="100%">
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                             title={<CustomTitle />}
                            data={TableData}
                            columns={columns}
                            options={options}

                        />
                        

{showVoucher && (
                            <>
            {showVouchers && <VoucherPopup data={voucherData} attachmentData={attachmentData} openPopup={true} />}          

                                <div>
                                    <MUIDataTable
                                        title={"Travel Voucher Details User Wise"}
                                        data={userVoucherData}
                                        columns={detailedUserColumns}
                                        options={options}

                                    />
                                      {userVoucherData.length > 0 && userVoucherData.every(item => item.status === "Pending") && (
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <Button variant="contained" color="primary" onClick={handleOpenRemarkDialog}>Action</Button>
                                        </Box>
                                    )}
                                </div>

                                {/* Remark Dialog */}
                                <Dialog open={openRemarkDialog} onClose={handleCloseRemarkDialog}>
                                    <DialogTitle>Enter Admin Remark</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="adminRemark"
                                            label="Admin Remark"
                                            type="text"
                                            fullWidth
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseRemarkDialog} color="primary">
                                            Cancel
                                        </Button>
                                        <Button onClick={handleAcceptAll} color="primary">
                                            Accept All
                                        </Button>
                                        <Button onClick={handleRejectAll} color="secondary">
                                            Reject All
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}
                        
                    </ThemeProvider>
                </Box>
            </div>
        </>
    )
}

export default Table;
