import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginSlg from "../Images/LoginSlg.png";
import { AiOutlineUser, AiOutlineLock, AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../redux-toolkit/slice/authSlice';
import Captcha from '../component/Captcha';
import loaderimg from '../Images/loader1.gif';


const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loader state

  const captchaRef = useRef();

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault();
  
    // Start the loader
    setLoading(true);
  
    // Verify CAPTCHA
    if (!captchaRef.current.verifyCaptcha()) {
      setError('CAPTCHA verification failed. Please try again.');
      captchaRef.current.regenerateCaptcha();
      setLoading(false); // Stop the loader if CAPTCHA fails
      return;
    }
  
    try {
      const url = `https://expenseapi.sarahtech.com/api/Accounts/auth_user`;
      const data = {
        email: email,
        password: password,
      };
  
      const response = await axios.post(url, data);
      dispatch(loginSuccess(response.data));
      toast.success(response.data.status);
  
      // Get the last visited page from sessionStorage
      const lastVisitedPage = sessionStorage.getItem('lastVisitedPage');
  
      // Redirect to the last visited page if it exists, otherwise, navigate based on the role
      if (lastVisitedPage) {
        navigate(lastVisitedPage, { replace: true });
        // Optionally, you can remove the last visited page from sessionStorage after redirection
        sessionStorage.removeItem('lastVisitedPage');
      } else {
        // Navigate based on the role
        if (response.data.role === 'User' || response.data.role === 'HR') {
          navigate('/Dashboard');
        } else if (response.data.role === 'Admin') {
          navigate('/DashboardAdmin');
        } else if (response.data.role === 'Accountant') {
          navigate('/DashboardAccount');
        }
      }
    } catch (err) {
      toast.error(err.response?.data || 'Login failed. Please try again.');
      captchaRef.current.regenerateCaptcha();
    } finally {
      // Stop the loader
      setLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-[#DBE8F1] py-12 px-4 sm:px-6 lg:px-8">
      {loading && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center">
        <img
            src={loaderimg}
            alt="Images"
            className="w-15 h-15 py-3"
          />
        </div>
      )}

      <div className="flex flex-col sm:flex-row shadow-lg shadow-amber-900 justify-center w-full max-w-screen-md">
        {/* Image Section */}
        <div className="sm:w-1/3 flex justify-center items-center bg-[#54BCE9]">
          <img
            src={LoginSlg}
            alt="Images"
            className="w-24 h-20 md:w-32 md:h-36 py-3"
          />
        </div>

        {/* Form Section */}
        <div className="bg-white sm:w-2/3 flex justify-center items-center">
          <div className="bg-white rounded-br px-8 py-8 sm:py-20 sm:px-12 lg:px-20">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Sign in</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-3">
                <div>
                  <h2 className="font-semibold text-md">User Name</h2>
                </div>
                <div className="flex items-center border-b border-gray-300">
                  <span className="mr-2 text-gray-500"><AiOutlineUser /></span>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field focus:outline-none w-full overflow-hidden text-ellipsis whitespace-nowrap"
                    style={{ minWidth: '0', flex: '1 1 auto' }}
                    disabled={loading} // Disable input while loading
                  />
                </div>

                <div>
                  <h2 className="font-semibold text-md">Password</h2>
                </div>
                <div className="flex items-center border-b border-gray-300">
                  <span className="mr-2 text-gray-500"><AiOutlineLock /></span>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field focus:outline-none"
                    disabled={loading} // Disable input while loading
                  />
                  <span className="cursor-pointer" onClick={togglePasswordVisibility}>
                    {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </span>
                </div>

                <div className="flex items-center justify-between gap-8">
                  <Captcha ref={captchaRef} />
                </div>

                {error && <div style={{ color: 'red' }}>{error}</div>}

                <div>
                  <button
                    type="submit"
                    className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? 'Signing in...' : 'Sign in'} {/* Change button text during loading */}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
