import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import Table from "../component/Table";
import UserAnalytics from "../component/UserAnalytics";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import formatExpenseDateInData from "../utilities/formatExpenseDateInData";
import getFileNameAndType from "../utilities/getFileNameAndType";






const EditVoucher = () => {
    const location = useLocation();
    const eventId = location.state?.eventId;
    const voucherId = location.state?.voucherId;

    // Use eventId in your component logic
    console.log('Event ID:', eventId);
    console.log('Voucher ID:', voucherId);
    const [attachmentData, setAttachmentData] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [isFood, setIsFood] = useState(false)
    const [isFuel, setIsFuel] = useState(false)
    const [isTravel, setIsTravel] = useState(false)
    const [isHotel, setIsHotel] = useState(false)
    const [isParking, setIsParking] = useState(false)
    const [data, setData] = useState(false)
    const [isMisc, setIsMisc] = useState(false)
    const [authExpenseList, setAuthExpenseList] = useState([]);
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [refreshList, setRefresheList] = useState(0)
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState(0);
    const [eventIdVoucher, setEventIdVoucher] = useState(0);
    const [isAttachmentButtonVisible, setIsAttachmentButtonVisible] = useState(false);

    const defaultValue = 0;

    const initialFormData = {
        expenseType: '',
        expenseSubType: '',
        expenseDate: '',
        expenseAmount: '',
        Attachments: '',
        description: '',
        fromPlace: '',
        toPlace: '',
        noOfPerson: '',
        distanceTravelled: '',
        fromDate: '',
        toDate: '',
        status: 'Pending',
        HotelName: '',
        Location: '',
        userId: null,
        filledByUserId: null,
        voucherId: null,
        donationFor: '',
        donationType: '',
        eventId: null,
        vehicleType: '',
        ExpenseName:''
    };

    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();
    const userData = useSelector(state => state.auth.user);
    const actionId = useSelector((state) => state.event.actionId)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);


    console.log(actionId);
    useEffect(() => { setEventIdVoucher(actionId) }, [eventIdVoucher]);
    console.log('object', attachmentData.attachments)


    const fetchAttachment = async () => {
        setIsAttachmentButtonVisible(false);
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_Attachments_details_by_voucher_id/${voucherId}`;
        try {
            const response = await axios.get(url);
            const data = response.data;
            setAttachmentData(data);
            setIsAttachmentButtonVisible(true);

  
            console.log('User Attachment Data', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    }

    const fetchUserVoucherData = async () => {
      
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            setUserVoucherData(data);
            const formatedExpenseDateWithData = formatExpenseDateInData(data);
            setFormData(formatedExpenseDateWithData);
            console.log('User Voucher Data', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    };


    const fetchData = async () => {
        try {
            const userId = userData.userId;
            const response = await axios.get(`https://expenseapi.sarahtech.com/api/NewUser/get_expense_types_by_userid/${userId}`);
            if (response.data) {
                const expenseTypeIds = new Set();
                const uniqueExpenseTypes = [];

                response.data.expenseTypesList.forEach(item => {
                    if (!expenseTypeIds.has(item.expenseTypeId)) {
                        uniqueExpenseTypes.push({
                            expenseTypeId: item.expenseTypeId,
                            expenseTypeName: item.expenseTypeName
                        });
                        expenseTypeIds.add(item.expenseTypeId);
                    }
                });

                setExpenseTypes(uniqueExpenseTypes);
                setAuthExpenseList(response.data.expenseTypesList);
            } else {
                console.error('Data received is not an array:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };









    const fetchEventData = async () => {
        try {
            const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_event_id/${eventId}`);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            // Directly use the response object as it's not an array
            const firstEvent = data;

            if (firstEvent) {
                // Extract and format the required fields
                const eventName = firstEvent.eventName || 'No Name provided';
                const task = firstEvent.task || 'No task provided';
                const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;
                const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;

                const trimmedData = {
                    task,
                    entryDate,
                    eventName,
                    expenseTypeId,
                };

                console.log('Event Data:', trimmedData);
                setData(trimmedData);
                return trimmedData;
            } else {
                // Handle case when there is no event
                console.warn('No event found.');
                return {}; // Return empty object if no event found
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return {}; // Return empty object in case of error
        }
    };


    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({
    //         ...formData,
    //         Attachment: file,
    //     });
    //     console.log('File data uploaded', file)
    // }


    // const handleFileChange = (e) => {
    //     const selectedFiles = Array.from(e.target.files);
    //     // setAttachment(selectedFiles);
    
      
    //     // Limit to 3 files and show alert if exceeded
    //     if (selectedFiles.length > 3) {
    //       alert("You can only upload up to 3 files.");
    //       selectedFiles.splice(3); // Trim the array to the first 3 files if more than 3 are selected
    //     }
      
    //     // Update formData with the trimmed list of files
    //     setFormData((prevFormData) => ({
    //       ...prevFormData,
    //       Attachments: selectedFiles,
    //     }));
      
    //     // Log files to verify the update
    //     console.log("Files uploaded:", selectedFiles);
    //   };
    const extractFileNameAndType = (path) => {
        if (typeof path !== 'string') {
            console.error('Invalid path:', path);
            return { fileName: null, fileType: null };
        }
    
        const parts = path.split(/[/\\]/).pop().split('.');
        return {
            fileName: parts[0], // File name before the extension
            fileType: parts[1] || 'png', // Default to 'png' if no extension is present
        };
    };
    
    const attPicFileName = attachmentData?.attachments
        ? extractFileNameAndType(attachmentData.attachments[0]).fileName
        : null;
    
    const attPicFileType = attachmentData?.attachments
        ? extractFileNameAndType(attachmentData.attachments[0]).fileType
        : 'png';
    
    const attPicUrl = attPicFileName
        ? `https://expenseapi.sarahtech.com/api/Files/view/${encodeURIComponent(attPicFileName)}.${attPicFileType}`
        : null;
    
    console.log('attPicFileName', attPicFileName);
    console.log('attPicFileType', attPicFileType);
    console.log('attPicUrl', attPicUrl);
    
    const fetchExistingFile = async (url, fileName, fileType) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new File([blob], `${fileName}.${fileType}`, { type: blob.type });
        } catch (error) {
            console.error("Error fetching existing file:", error);
            return new File(
                ["Placeholder content for existing file"],
                `${fileName}.${fileType}`,
                { type: `image/${fileType}` }
            );
        }
    };
    
    const handleFileChange = async (e) => {
        const selectedFiles = Array.from(e.target.files);
    
        // Limit the total attachments to 3
        if (selectedFiles.length > 3) {
            alert("You can only upload up to 3 files.");
            return;
        }
    
        console.log("Selected files:", selectedFiles);
    
        const existingAttachmentsPromises = attachmentData?.attachments?.map(async (attachment) => {
            const { fileName, fileType } = extractFileNameAndType(attachment);
            const fileUrl = `https://expenseapi.sarahtech.com/api/Files/view/${encodeURIComponent(fileName)}.${fileType}`;
            return await fetchExistingFile(fileUrl, fileName, fileType);
        }) || [];
    
        const existingAttachments = await Promise.all(existingAttachmentsPromises);
    
        console.log("Existing attachments:", existingAttachments);
    
        // Combine and limit to 3 attachments
        const combinedAttachments = [...existingAttachments, ...selectedFiles].slice(0, 3);

        setFormData((prevFormData) => ({
            ...prevFormData,
            Attachments: combinedAttachments,
          }));
    
        console.log("Combined attachments:", combinedAttachments);
    };
    
      
      
      
      const handleRemoveAttachment = (index) => {
        setFormData((prevFormData) => {
          const updatedAttachments = prevFormData.Attachments.filter((_, i) => i !== index);
          return { ...prevFormData, Attachments: updatedAttachments };
        });
      };
      
      const AttachmentView = ({ attachments, onRemove }) => (
        <div className="flex flex-wrap mt-4">
          {attachments.map((attachment, index) => (
            <div
              key={index}
              className="relative flex items-center justify-center mr-4 mb-4 border rounded p-2 w-32 h-32 bg-gray-100"
            >
              {attachment.type === "existing" ? (
                <a
                  href={`https://expenseapi.sarahtech.com/api/Files/view/${attachment.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="truncate text-center text-sm text-blue-500 underline"
                >
                  {attachment.name}
                </a>
              ) : attachment.type === "application/pdf" ? (
                <p className="truncate">{attachment.name}</p>
              ) : (
                <img
                  src={URL.createObjectURL(attachment)}
                  alt={`Attachment ${index + 1}`}
                  className="w-full h-full object-cover rounded"
                />
              )}
              <button
                onClick={() => onRemove(index)}
                className="absolute top-0 right-0 bg-red-500 text-white w-5 h-5 rounded-full flex items-center justify-center text-xs font-bold hover:bg-red-600"
                title="Remove"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      );
      

    const handleSubmit = async (e) => {
        debugger
        setFormData({
            ...formData,
            // userName: userData.userName,
            userId: userData.userId,
            filledByUserId: userData.userId,
            // role: userData.role,
            eventId: eventId,
            voucherId: voucherId
        });
        console.log('User Data', userData);

        e.preventDefault();
        setShowModal(true)


    };

    useEffect(() => {
        if (formData.expenseSubType === 'FOOD EXPENSE') {
            setIsFuel(false);
            setIsTravel(false);
            setIsFood(true);
            setIsMisc(false);

            console.log('set is FOOD', isFood);
        }

        if (formData.expenseSubType === 'HOTEL EXPENSE') {
            setIsFuel(false);
            setIsMisc(false);
            setIsFood(true);
            setIsHotel(true);
        }

        if (formData.expenseSubType === 'FUEL EXPENSE') {
            setIsFuel(true);
            setIsHotel(false);
            setIsMisc(false);
            setIsFood(false);

        }

        if (formData.expenseSubType === 'PARKING') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

        }

        if (formData.expenseSubType === 'TOLL') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

            console.log('set is Travel', isTravel);
        }

        if (formData.expenseSubType === 'MISCELLANEOUS EXPENSE') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(true);
            console.log('set is Travel', isTravel);
        }
        console.log('form data changed: ', formData);
    }, [formData]);




    const handleSendData = async () => {
        debugger
        try {
            console.log('Sending User Data:', formData);
    
            const hotelDetails = `${formData.HotelName}, ${formData.Location}`;
       
    
            // Prepare the form data to send
            const formDataToSend = new FormData();
        
            formDataToSend.append('HotelDetails', hotelDetails);
    
            for (const key in formData) {
                if (key !== 'Attachments') {
                    // Convert null/undefined to an empty string for inclusion
                    const value = formData[key] ?? ''; // Nullish coalescing operator
                    formDataToSend.append(key, value);
                }
            }
    
            // Add attachments if present, prioritize existing if new attachments are null
           
            if (formData.Attachments && formData.Attachments.length > 0) {
                
                formData.Attachments.forEach((file) => {
                    formDataToSend.append('Attachments', file);
                });
            } else if (attachmentData?.attachments?.length > 0) {
                // Create promises to fetch existing files
                const existingAttachmentsPromises =
                    attachmentData.attachments.map(async (attachment) => {
                        const { fileName, fileType } = extractFileNameAndType(attachment);
                        const fileUrl = `https://expenseapi.sarahtech.com/api/Files/view/${encodeURIComponent(fileName)}.${fileType}`;
                        return await fetchExistingFile(fileUrl, fileName, fileType);
                    }) || [];
            
                // Wait for all promises to resolve
                const existingAttachments = await Promise.all(existingAttachmentsPromises);
            
                // Append the resolved attachments to formDataToSend
              
                existingAttachments.forEach((file) => {
                    formDataToSend.append('Attachments', file);
                });
            }
            

            
            const response = await axios.post(
                'https://expenseapi.sarahtech.com/api/Accounts/reapply_voucher',
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
    
            console.log('Response:', response.data);
            toast.success(response.data.status);
    
            // Navigate based on role
            if (userData.role === 'User') {
                navigate('/Dashboard');
            } else if (userData.role === 'Accountant') {
                navigate('/DashboardAccount');
            }
    
            // Reset form data
            setFormData({
                officialPersonal: '',
                expenseType: '',
                expenseSubType: '',
                expenseAmount: '',
                description: '',
                vehicleType: '',
                fromSource: '',
                toSource: '',
                distanceTravelled: '',
                noOfPerson: '',
                fromDate: '',
                toDate: '',
                userId: null,
                filledByUserId: 'null',
                ExpenseName: '',
                donationFor: '',
                donationType: '',
                eventId: null,
                expenseDate: '',
            });
    
            // Refresh the list
            setRefresheList(refreshList + 1);
        } catch (error) {
            console.error('Error:', error);
    
            if (error.response) {
                // Server responded with an error
                console.error('Response Error:', error.response.data);
                toast.error(error.response.data);
            } else if (error.request) {
                // No response received
                console.error('Request Error:', error.request);
                toast.error('No response received from the server');
            } else {
                // Other errors
                console.error('Processing Error:', error.message);
                toast.error('An error occurred while processing your request');
            }
        }
    };
    


    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            eventId: eventIdVoucher
        }));

        console.log('User Voucher Fill EventIdVoucher', eventIdVoucher)
    }, [eventIdVoucher]);


    const handleChange = (e) => {
       
        debugger
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        const expenseTypeIds = {
            "OFFICE EXPENSE": 1,
            "TRAVEL EXPENSE": 2,
            "STAFF AND WELFARE EXPENSE": 3,
            "REPAIR & MAINTENANCE EXPENSE": 4,
            "BUSINESS & PROMOTION EXPENSE": 5,
            "DONATION EXPENSE": 6,
            "LEGAL & PROFESSIONAL CHARGES": 7,
            "OFFICE RENT": 8,
            "PRINTING & STATIONARY EXPENSE": 9,
            "ELECTRICITY EXPENSE": 10,
            "INSTALLATION EXPENSE": 11,
            "POSTAGE & COURIER EXPENSE": 12,
            "FREIGHT CHARGES": 13,
            "VENDOR PAYMENT": 14
        };

        if (name === "ExpenseType") {
            const expenseTypeId = expenseTypeIds[value] || null; // Default to null if not found
            console.log(expenseTypeId);
            setSelectedExpenseTypeId(expenseTypeId);
        }




        if (name === 'SubType' && value === 'FOOD EXPENSE') {
            setIsFuel(false);
            setIsTravel(false);
            setIsFood(true);
            setIsMisc(false);

            console.log('set is FOOD', isFood);
        }

        if (name === 'SubType' && value === 'HOTEL EXPENSE') {
            setIsFuel(false);
            setIsMisc(false);
            setIsFood(true);
            setIsHotel(true);
        }

        if (name === 'SubType' && value === 'FUEL EXPENSE') {
            setIsFuel(true);
            setIsHotel(false);
            setIsMisc(false);
            setIsFood(false);

        }

        if (name === 'SubType' && value === 'PARKING') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

        }

        if (name === 'SubType' && value === 'TOLL') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

            console.log('set is Travel', isTravel);
        }

        if (name === 'SubType' && value === 'MISCELLANEOUS EXPENSE') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(true);
            console.log('set is Travel', isTravel);
        }
    }


    useEffect(() => {
    }, [isFuel]);

    useEffect(() => {
        console.log('set is Travel', isTravel);
    }, [isTravel]);


    useEffect(() => {
        fetchData();
        fetchUserVoucherData();

    }, []);

    useEffect(() => {
        console.log(formData.expenseDate)
    }, [formData.expenseDate]);





    useEffect(() => {
        console.log('store data of User', userData);
        console.log('store data of User', isLoggedIn);
        fetchEventData();
        fetchAttachment();

    }, [refreshList]);


    useEffect(() => {
        console.log('Data:', data);
    }, [data]);

    const getHref = () => {
        if (userData.role === 'User') {
            return '/Dashboard';
        } else if (userData.role === 'Accountant') {
            return '/DashboardAccount';
        }
        return '#'; // Default href if no role matches
    };


    return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                <a href={getHref()} className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
        </a>
                    <p className="text-gray-800 font-poppins ml-2">{data.eventName
                        ? data.eventName
                        : "No task provided"}</p>
                </div>
                <div className="flex flex-row bg-white mx-2 py-4">
                    <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
                        {/* Content on the left side */}
                        <h1 className="text-xl font-bold mb-1 mx-2">Event Description:</h1>
                        <p className="text-xs text-slate-400 font-poppins mx-2">{data.task ? data.task : "No task provided"}</p>
                    </div>
                    <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
                        <h1 className="text-xl font-bold mx-2">Date:</h1>
                        <p className="text-xs text-slate-400 font-poppins mx-2">{data.entryDate ? data.entryDate : "No date provided"}</p>
                    </div>
                </div>



                {/* form */}
                {/* form */}
                <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center h-screen">
                    <div className="flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-2">
                        {/* Left section */}
                        <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-2">

                        <div className="md:flex md:items-center 3xl:mb-6 mb-3">
              <div className="md:w-1/3">
                <label
                  htmlFor="Expense Type"
                  className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                >
                  Expense Type:
                </label>
              </div>
              <div className="md:w-2/3">

                {/* <select
                  id=""
                  name="SubType"
                  value={formData.SubType}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                >

                       <option value="" disabled>Select an expense type</option>
                  {Object.keys(authExpenseList)
                    .filter(key => authExpenseList[key].expenseTypeId === parseInt(selectedExpenseTypeId))
                    .map(key => (
                      <option key={key} value={authExpenseList[key].subTypeName}>
                        {authExpenseList[key].subTypeName}
                      </option>
                    ))}


                </select> */}

                <select
                  id="expenseSubType"
                  name="expenseSubType"
                  value={formData.expenseSubType}
                  onChange={(e) => {
                    const selectedSubType = e.target.value;
                    const selectedSubTypeData = authExpenseList.find(item => item.subTypeName === selectedSubType);
                    const selectedExpenseType = selectedSubTypeData ? selectedSubTypeData.expenseTypeName : '';

                    handleChange(e); // Call your handleChange function with the event
                    setFormData({
                      ...formData,
                      expenseSubType: selectedSubType, // Update SubType in formData
                      ExpenseType: selectedExpenseType // Update ExpenseType in formData
                    });
                  }}
                  
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                >

                     <option value="" disabled>Select an expense type</option>
                  {authExpenseList.map((items, key) => (
                      <option key={key} value={items.subTypeName}>
                        {items.subTypeName} - {items.expenseTypeName}
                      </option>
                    ))}


                </select>
              </div>
            </div>





                            <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Expense Amount"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Expense Amount:
                                    </label>
                                </div>

                                <div className="md:w-2/3">
                                    <input
                                        type="tel"
                                        placeholder="Expense Amount"
                                        name="expenseAmount"
                                        value={formData.expenseAmount}
                                        onChange={handleChange}
                                        required
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                    />
                                </div>
                            </div>
                            {/* {isAttachmentButtonVisible ? (<>
                                <div className="md:flex md:items-center mb-3 2xl:mb-8">
                                    <div className="md:w-1/2 px-1">
                                        <a target="blank" href={`https://expenseapi.sarahtech.com/api/Files/view/${getFileNameAndType(attachmentData?.attachments).fileName}.${getFileNameAndType(attachmentData?.attachments).fileType}`} download="adf.png">
                                            <button className="text-sm bg-info text-white px-4 py-2 rounded-lg mt-0 w-full">View Attachment</button>
                                        </a>
                                    </div>



                                    <div className="md:w-1/2 px-1">
                                        <button onClick={() => { setIsAttachmentButtonVisible(false) }} className="text-sm bg-info text-white px-4 py-2 rounded-lg mt-0 w-full">Add New </button>
                                    </div>
                                </div>

                            </>) : (<div className="md:flex md:items-center mb-3 2xl:mb-8">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Attachment"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Attachment:
                                    </label>
                                </div>
                                <div className="md:w-2/3 flex">
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="document"
                                        type="file"
                                        accept=".pdf,.doc,.docx"
                                        onChange={handleFileChange}

                                    />


                                </div>
                            </div>)} */}






{isAttachmentButtonVisible ? (
  <div className="md:flex md:flex-wrap md:items-center mb-3 2xl:mb-8">
    {attachmentData?.attachments.map((attachment, index) => {
      const { fileName, fileType } = getFileNameAndType(attachment);
      return (
        <div key={index} className="relative md:w-1/2 px-1 mb-2">
          <a
            target="_blank"
            href={`https://expenseapi.sarahtech.com/api/Files/view/${fileName}.${fileType}`}
            download={`${fileName}.${fileType}`}
          >
            <button className="text-sm bg-info text-white px-4 py-2 rounded-lg w-full">
              View Attachment {index + 1}
            </button>
          </a>
        </div>
      );
    })}
    <div className="md:w-1/2 px-1">
      <button
        onClick={() => setIsAttachmentButtonVisible(false)}
        className="text-sm bg-info text-white px-4 py-2 rounded-lg w-full"
      >
        Add New
      </button>
    </div>
  </div>
) : (
  <div className="md:flex md:items-center mb-3 2xl:mb-8">
    <div className="md:w-1/3">
      <label
        htmlFor="Attachment"
        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
      >
        Attachment:
      </label>
    </div>
    <div className="md:w-2/3 flex">
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="document"
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
      />
    </div>
    <AttachmentView
      attachments={formData.Attachments || []}
      onRemove={handleRemoveAttachment}
    />
  </div>
)}




                            {isFuel && (
                                <>

<div className="md:flex md:items-center mb-3">
<div className="md:w-1/3">
<label
htmlFor="Vehicle Type"
className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
>
Vehicle Type:
</label>
</div>
<div className="md:w-2/3">
<input
type="text"
placeholder="Vehicle Type"
name="vehicleType"
value={formData.vehicleType}
onChange={handleChange}
className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
/>
</div>
</div>


                                </>

                            )}
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Description"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Description:
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input
                                        type="text"
                                        placeholder="description..."
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="border border-gray-300 rounded-lg px-4 py-4 mr-2 w-full"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* right section */}
                        <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-2">


                            {/* <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Sub Type"
                                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Sub-Type:
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select
                                        id=""
                                        name="ExpenseSubType"
                                        value={formData.expenseSubType}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                    >

                                        { {Object.keys(authExpenseList).filter(expenseItems => {expenseItems.expenseTypeId = 1})} }
                                        <option value="" disabled>{userVoucherData.expenseSubType}</option>

                                        { {Object.keys(authExpenseList)
                                            .filter(key => authExpenseList[key].expenseTypeId === parseInt(selectedExpenseTypeId))
                                            .map(key => (
                                                
                                                <option key={key} value={authExpenseList[key].subTypeName}>
                                                    {authExpenseList[key].subTypeName}
                                                </option>
                                            ))} }


                                    </select>
                                </div>
                            </div> */}


                            <div className="md:flex md:items-center mb-3 2xl:mb-6">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Expense Date"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Expense Date:
                                    </label>
                                </div>

                                <div className="md:w-2/3">
                                    <input
                                        type="date"
                                        id="expenseDate"
                                        name="expenseDate"
                                        value={formData.expenseDate}
                                        onChange={handleChange}

                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                    />
                                   
                                </div>

                            </div>

                            {isMisc && (
                                <>
                                    <div className="md:flex md:items-center mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Expense Name"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Expense Name:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Expense Name"
                                                name="ExpenseName"
                                                value={formData.ExpenseName}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                </>

                            )}

                            {isHotel && (
                                <>

                                    <div className="md:flex md:items-center mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Hotel Name"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Hotel Name:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Hotel Name"
                                                name="Hotel"
                                                value={formData.Hotel}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Hotel Location"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Hotel Location:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Location"
                                                name="location"
                                                value={formData.location}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                </>

                            )}

                            {isFood && (
                                <>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="No Of Person"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                No Of Person:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="tel"
                                                placeholder="No Of Person."
                                                name="noOfPerson"
                                                value={formData.noOfPerson}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                </>

                            )}

                            {isFuel && (
                                <>
                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor=" From Source"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                From Source:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="From Place"
                                                name="fromSource"
                                                value={formData.fromSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="To Source"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                ToSource :
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Destination Place"
                                                name="toSource"
                                                value={formData.toSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Distance"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Distance:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="tel"
                                                placeholder="Distance in KMS."
                                                name="distanceTravelled"
                                                value={formData.distanceTravelled}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                </>

                            )}





                            {/* travel */}
                            {isTravel && (
                                <>
                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor=" From Source"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                From Source:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="FromPlace"
                                                name="fromSource"
                                                value={formData.fromSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="ToPlace"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                To :
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="To Place"
                                                name="ToPlace"
                                                value={formData.toPlace}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>





                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="From Date"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                From Date:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="date"
                                                name="FromDate"
                                                value={formData.fromDate}
                                                onChange={handleChange}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="To Date"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                To Date:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="date"
                                                name="ToDate"
                                                value={formData.toDate}
                                                onChange={handleChange}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                </>

                            )}





                        </div>


                    </div>
                    <div className="flex justify-center">
                        <div className="my-2">
                            <button
                                onClick={handleSubmit}
                                className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
                            >
                                Submit
                            </button>
                        </div>





                        {/* Modal component */}
                        <FormDetailsPopup formData={formData} showModal={showModal} setShowModal={setShowModal} handleSendData={handleSendData} />







                    </div>
                </div>




            </div>
        </div>

    )
}

export default EditVoucher